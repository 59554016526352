import { FunctionComponent, forwardRef } from 'react'
import { Dialog, Slide, Box } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import styled from 'styled-components/macro'

const StyledDialog = styled(Dialog)`
  && {
    overflow-y: hidden;

    .MuiDialog-paper {
      border-radius: 15px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  &.MuiDialog-paper {
    overflow-y: hidden;
  }
`

const TopBox = styled(Box)`
  border-bottom: 1px solid #b4bdca !important;
`
const GreyCloseIcon = styled(ClearIcon)`
  color: grey;
`

const Transition = forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

interface FullScreenModalProps {
  title: string
  open: boolean
  onClose: Function
}

export const FullScreenModal: FunctionComponent<FullScreenModalProps> = ({ title, open, onClose, children }) => {
  return (
    //@ts-ignore
    <StyledDialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      {/*
      //@ts-ignore*/}
      <TopBox display="flex" margin="28px 23px" paddingBottom="20px" onClick={onClose}>
        <Box display="flex" flexGrow={1}>
          <GreyCloseIcon />
        </Box>
        <Box display="flex" marginLeft="auto">
          {title}
        </Box>
      </TopBox>
      {children}
    </StyledDialog>
  )
}
