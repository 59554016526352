import { useState, useContext, useLayoutEffect } from 'react'
import { useAuth } from 'pages/auth/hooks/useAuth'
import MessagingContext from 'contexts/messaging/MessagingProvider'
import { Messages, Message } from 'common/types/message.types'
import { useMessageApi } from 'hooks/use-message-api/useMessageApi'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import { useLocation } from 'react-router-dom'

export const useMessages = () => {
  const { fetchMessages, updateStatus } = useMessageApi()
  const [openChatsDrawer, setChatsDrawer] = useState(false)
  const { backendUser } = useAuth()
  const {
    newReceivedMessage,
    setReceiver,
    setOpen,
    newSentMessage,
    clearSentMessage,
    clearArchivedConversationUser,
    archivedConversationUser,
    isOpen,
    receiver
  } = useContext(MessagingContext)
  const { getItem } = useLocalStorage()
  const { pathname } = useLocation()
  const [page, setPage] = useState(1)
  const [messages, setMessages] = useState<Message[]>([])
  const [totalMessages, setTotalMessages] = useState<number>()
  const [showNotification, setShowNotification] = useState<boolean>(false)

  const getMessages = async () => {
    if (backendUser?.id) {
      const messagesList: Messages = await fetchMessages(backendUser?.id, page, 10)
      const uniqueMessage = messagesList?.data?.filter(x => {
        const existingMessage = messages?.find(y => y?.id === x?.id)
        if (!existingMessage) return x
      })
      setTotalMessages(Number(messagesList?.totalCount))
      setMessages(messages?.concat(uniqueMessage))
    }
  }

  useLayoutEffect(() => {
    if (backendUser?.id) {
      getMessages()
    }
  }, [page])

  useLayoutEffect(() => {
    if (newReceivedMessage) {
      if (messages && messages.length > 0) {
        const messageIndex = messages.findIndex(
          (message: Message) =>
            message.senderId === newReceivedMessage?.senderId || message.receiverId === newReceivedMessage?.senderId
        )
        if (messageIndex > -1) {
          const copyOfMessages = [...messages]
          copyOfMessages[messageIndex] = newReceivedMessage
          setMessages(copyOfMessages)
        } else {
          setMessages(prev => [newReceivedMessage, ...prev])
        }
      }
    }
  }, [newReceivedMessage])

  useLayoutEffect(() => {
    if (newSentMessage) {
      if (messages && messages.length > 0) {
        const messageIndex = messages.findIndex(
          (message: Message) =>
            message.senderId === newSentMessage?.receiverId || message.receiverId === newSentMessage?.receiverId
        )
        if (messageIndex > -1) {
          const copyOfMessages = [...messages]
          copyOfMessages[messageIndex] = newSentMessage
          setMessages(copyOfMessages)
        } else {
          setMessages(prev => [newSentMessage, ...prev])
        }
      }
      clearSentMessage()
    }
    if (archivedConversationUser) {
      if (messages && messages.length > 0) {
        const messageIndex = messages.findIndex(
          (message: Message) =>
            message.senderId === archivedConversationUser || message.receiverId === archivedConversationUser
        )
        if (messageIndex > -1) {
          const copyOfMessages = [...messages]
          copyOfMessages.splice(messageIndex, 1)
          setMessages(copyOfMessages)
        }
      }
      clearArchivedConversationUser()
    }
  }, [newSentMessage, archivedConversationUser])

  useLayoutEffect(() => {
    const messageInStorage = getItem('messages')
    let messageReceived
    let lastCreatedAt = ''
    let sentBy = ''
    if (messageInStorage) {
      messageReceived = JSON.parse(messageInStorage)
      lastCreatedAt = messageReceived.createdAt
      sentBy = messageReceived.senderId
    }

    if (messages?.length > 0) {
      if (openChatsDrawer) {
        setShowNotification(false)
      } else if (isOpen && receiver && receiver?.id === newReceivedMessage?.senderId) {
        setShowNotification(false)
      } else if (pathname === '/messages') {
        setShowNotification(false)
      } else if (
        messages?.some(
          msg =>
            Date.parse(msg.createdAt) > Date.parse(lastCreatedAt) &&
            msg.receiverId == backendUser?.id &&
            msg.status === 'unread'
        )
      ) {
        setShowNotification(true)
      } else {
        setShowNotification(false)
      }
    } else if (!messageInStorage && messages?.some(msg => msg.status === 'unread')) {
      setShowNotification(true)
    }
  }, [messages, newReceivedMessage])

  const updateMessageStatus = async (messageId?: string) => {
    const updatedMessage = await updateStatus(messageId)
    return updatedMessage
  }

  return {
    openDrawer: openChatsDrawer,
    setDrawer: setChatsDrawer,
    user: backendUser,
    messages,
    setPage,
    page,
    totalMessages,
    showNotification,
    setShowNotification,
    setReceiver,
    setOpen,
    getMessages,
    updateMessageStatus
  } as const
}
