export const setItem = (key: string, value: any) => {
    localStorage.setItem(key, value)
}
export const getItem = (key: string) => {
    return localStorage.getItem(key)
}
export const clearItem = (key: string) => {
    localStorage.removeItem(key)
}

export const clearAll = () => {
    localStorage.clear()
}

export const useLocalStorage = () => {
    return {
        setItem,
        getItem,
        clearItem,
        clearAll
    } as const
}
