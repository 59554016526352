import { UserGroup, EmployeeRoles, UserFunction } from 'common/types/admin.types'
import { useHttp } from 'hooks/use-http/useHttp'
import { INITIAL_PAGE_SIZE, INITIAL_PAGE_STATE } from 'pages/administration/components/user-management/constants'
import { useAuth } from 'pages/auth/hooks/useAuth'

export const useUserManagementApi = () => {
  const { get, post, destroy, put } = useHttp()
  const { backendUser } = useAuth()

  const fetchUserGroups = async (pageNumber?: number, pageSize?: number) => {
    const response: UserGroup[] = await get(`admin-role`, { dieSilent: true })
    return response
  }

  const createUserGroups = async (role: UserGroup) => {
    const payload = {
      name: role?.name,
      description: role?.description,
      createdBy: backendUser?.username
    }
    const response: UserGroup = await post(`admin-role`, payload, { dieSilent: true })
    return response
  }

  const deleteUserGroups = async (adminRoleId: string) => {
    return await destroy(`admin-role/${adminRoleId}`, { dieSilent: true })
  }

  const fetchUserFunctions = async (pageNumber?: number, pageSize?: number) => {
    const response: UserFunction[] = await get(`user-function`, { dieSilent: true })
    return response
  }

  const fetchUserFunctionAndRoleByRole = async (roleId: string) => {
    const response = await get(`user-role-function/${roleId}`, { dieSilent: true })
    return response
  }

  const fetchUserFunctionsByUser = async (userId: string) => {
    const response: UserFunction[] = await get(`user-function/user/${userId}`)
    return response
  }

  const fetchAdminRolesAssignedToUser = async (userId: string) => {
    const response: UserGroup[] = await get(`admin-role/user/${userId}`)
    return response
  }

  const fetchUserGroupsNotCreatedByPartners = async (pageNumber?: number, pageSize: number = 10) => {
    const response = await get(`admin-role/non-partner/roles?page=${pageNumber}&pageSize=${pageSize}&dir=desc&sort=created_at`)
    return response
  } 

  const createUserAdminRoles = async (roleId: string, functionId: string) => {
    const payload = {
      functionId,
      roleId
    }
    const response = await post(`user-role-function`, payload, { dieSilent: true })
    return response
  }

  const createUserAdmin = async (roleId: string, userId: string) => {
    const payload = {
      userId: userId,
      roleId: roleId,
      createdBy: backendUser?.username
    }
    const response = await post(`user-admin-role`, payload, { dieSilent: true })
    return response
  }

  const createBulkUserFunction = async (adminRoleId: string, permissions: any[]) => {
    const payload = {
      userRoleFunctions: permissions
    }
    const response = await post(`user-role-function/${adminRoleId}/bulk`, payload, { dieSilent: true })
    return response
  }

  const fetchAdminUserAndRolesByRole = async (roleId: string, pageNumber: number = 1, pageSize: number = 10) => {
    const response = await get(`user-admin-role/${roleId}?page=${pageNumber}&pageSize=${pageSize}&dir=asc&sort=created_at`, { dieSilent: true })
    return response
  }

  const fetchEmployeeRoles = async (pageNumber: number = INITIAL_PAGE_STATE, pageSize: number = INITIAL_PAGE_SIZE) => {
    const response: EmployeeRoles = await get(
      `user-admin-role/employee/roles?page=${pageNumber}&pageSize=${pageSize}&dir=asc&sort=first_name`,
      { dieSilent: true }
    )
    return response
  }

  const deleteAssignedUsersByRole = async (adminRoleId: string, userId: string) => {
    return await destroy(`user-admin-role/admin/${userId}/role/${adminRoleId}`, { dieSilent: true })
  }

  const updateUserGroup = async (userGroup: UserGroup, roleId: string) => {
    const payload = {
      name: userGroup?.name
    }
    const response = await put(`admin-role/${roleId}`, payload, { dieSilent: true })
    return response
  }


  const deactivateUser = async (userId: string) => {
    const response = await destroy(`user-admin-role/admin/${backendUser?.id}/deactivate/${userId}`, { dieSilent: true })
    return response
  }

  const reactivateUser = async (userId: string) => {
    const response = await put(`user-admin-role/admin/${backendUser?.id}/reactivate-employee/${userId}`, {
      dieSilent: true
    })
    return response
  }

  return {
    fetchUserGroups,
    deactivateUser,
    createUserGroups,
    fetchUserFunctions,
    deleteUserGroups,
    fetchAdminUserAndRolesByRole,
    fetchEmployeeRoles,
    fetchUserFunctionAndRoleByRole,
    createUserAdminRoles,
    deleteAssignedUsersByRole,
    createUserAdmin,
    updateUserGroup,
    createBulkUserFunction,
    fetchUserFunctionsByUser,
    fetchAdminRolesAssignedToUser,
    fetchUserGroupsNotCreatedByPartners,
    reactivateUser
  } as const
}
