import { Response } from './response.types'
export interface UserGroup {
  [x: string]: any
  id: string
  name: string
  disabled?: boolean
  description?: string
  createdBy?: string
  createdAt?: string
}

export interface UserGroups extends Response {
  data: UserGroup[]
}

export interface UserFunction {
  id?: string
  name: string
  action: string
  isEnabled: boolean
  actionGroup: string
  actionUrl: string
  checked?: boolean
  functionType: string
}

export interface UserFunctions extends Response {
  data: UserFunction[]
}

export interface UserAdmin {
  id?: string
  userId: string
  roleId: string
  roleName?: string
  username?: string
  firstname?: string
  lastname?: string
  name?: string
  numOfRoles?: number
  createdAt?: string
  createdBy?: string
}

export interface UserAdmin extends Response {
  data: UserAdmin[]
}

export interface AdminsPartnerList {
  page: number
  pageSize: number
  sort: string
  totalCount: number
  data: UserAdmin[]
}

export interface AdminRoleFunction {}

export interface EmployeeRole {
  userId: string
  username: string
  employeeName: string
  numOfRoles: string
  roles: string
  isEmployeeDeactivated: boolean
}

export interface EmployeeRoles extends Response {
  data: EmployeeRole[]
}

export enum UserGroupActions {
  ADD_USERS = 'Add Admins',
  VIEW_USERS = 'View Admins',
  VIEW_PERMISSIONS = 'View Permissions',
  EDIT_USER_GROUP = 'Edit Admin Group',
  DISABLE_USER_GROUP = 'Disable Admin Group',
  ENABLE_USER_GROUP = 'Enable User Group',
  DELETE_USER_GROUP = 'Delete Admin Group'
}

export enum EmployeeListActions {
  ADD_USER_GROUP = 'Add to user group',
  DEACTIVATE_EMPLOYEE = 'Deactivate Employee',
  REACTIVATE_EMPLOYEE = 'Reactivate Employee'
}