import { Typography, Box } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Button } from 'common/atoms/Button/Button'
import { useHistory } from 'react-router-dom'

const Title = styled(Typography)`
  &.MuiTypography-h1 {
    color: #bdb9ff;
    font-weight: 600;
  }
`


const ErrorPage = () => {
  const history = useHistory()
  return (
    <Box flexDirection="column">
      <Box py={5} flex={1} flexDirection="column" display="flex" alignItems="center" justifyContent="center">
        <Title variant="h1" gutterBottom>
          404
        </Title>
        <Typography gutterBottom>This page was not found</Typography>
        <Button design='primary' onClick={() => history.push('/')}>
          Return to Home Page
        </Button>
      </Box>
    </Box>
  )
}

export default ErrorPage
