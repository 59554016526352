import ErrorPage from './ErrorPage'
import { Route, Switch } from 'react-router-dom'

export const SwitchWithError = ({ children }) => {
  return (
    <Switch>
      {children}
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  )
}
