import { Box, makeStyles, Typography } from '@material-ui/core'
import { ConfirmModal } from 'components/core/modal'
import { FunctionComponent, useState } from 'react'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
//@ts-ignore
import { useTheme } from 'styled-components'
import {
  LeftStyledReceivedMessage,
  RightStyledReceivedMessage,
  StyledAvatar,
  StyledConversationContainer,
  StyledEditText,
  StyledLargeDeleteIcon,
  StyledMessageList,
  StyledMessageText,
  StyledRow
} from './style'
import { Message } from 'common/types/message.types'
import { BackendUser } from 'common/types'
//@ts-ignore
import ReactTextFormat from 'react-text-format'
import { htmlToReactParser } from 'common/HTMLToReactParser'

const { MessageText } = require('@livechat/ui-kit')

interface DirectMessageListProps {
  activeChat?: Message[]
  editedMessage: string
  setEditedMessage: Function
  editMessageId: string
  setEditMessageId: Function
  sender: BackendUser
  formatDate: Function
  getTimeCreated: Function
  showEdit: boolean
  setShowEdit: Function
  disablePopup: boolean
  setDisablePopup: Function
  handleEdit: Function
  handleDelete: Function
  deleteMessageId: string
  setDeleteMessageId: Function
}
export const DirectMessagingList: FunctionComponent<DirectMessageListProps> = ({
  activeChat,
  editedMessage,
  setEditedMessage,
  editMessageId,
  setEditMessageId,
  sender,
  formatDate,
  getTimeCreated,
  showEdit,
  setShowEdit,
  disablePopup,
  setDisablePopup,
  handleEdit,
  handleDelete,
  deleteMessageId,
  setDeleteMessageId
}) => {
  const theme = useTheme()
  const { isDevice } = useScreenSizes()
  const [showDelete, setShowDelete] = useState(false)
  const groups = activeChat.reduce((groups, chat) => {
    const date = chat.createdAt.split('T')[0]
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(chat)
    return groups
  }, {})

  const useStyles = makeStyles({
    clickable: {
      color: theme.colors.primary
    }
  })
  const classes = useStyles()

  const customLinkDecorator = (decoratedHref: string, decoratedText: string, linkTarget: string, key: number) => (
    <a href={decoratedHref} key={key} target="_blank" rel="noopener" className={classes.clickable}>
      {decoratedText}
    </a>
  )

  return (
    <>
      <StyledConversationContainer
        isDevice={isDevice}
        style={{
          padding: '0 0.52rem',
          borderTop: `1px solid ${isDevice && theme.colors.supplementary1}`
        }}
      >
        <StyledMessageList isDevice={isDevice}>
          {Object.keys(groups).map(key => {
            return (
              <>
                <Typography align="center" style={{ fontSize: '14px', color: theme.colors.accent }}>
                  {formatDate(key)}
                </Typography>
                {groups[key].map((message, index) => {
                  if (message?.senderId !== sender?.id) {
                    return (
                      <>
                        <StyledRow
                          style={{
                            marginBottom: `${isDevice ? '0.7343839542vh' : '1vh'}`
                          }}
                          key={index}
                        >
                          <StyledAvatar src={message?.senderImage} />
                          <LeftStyledReceivedMessage isDevice={isDevice}>
                            <MessageText>
                              <ReactTextFormat allowedFormats={['URL']} linkDecorator={customLinkDecorator}>
                                {htmlToReactParser.parse(decodeURIComponent(message.content.message))}
                              </ReactTextFormat>
                              <Box display="flex" justifyContent="flex-end">
                                {message.edited && <StyledEditText>Edited</StyledEditText>}
                              </Box>
                            </MessageText>
                          </LeftStyledReceivedMessage>
                        </StyledRow>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <StyledRow
                          style={{
                            marginBottom: `${isDevice ? '0.7343839542vh' : '1vh'}`
                          }}
                          reverse
                          key={index}
                        >
                          <StyledAvatar src={message?.senderImage} />
                          <RightStyledReceivedMessage isDevice={isDevice}>
                            <StyledMessageText>
                              <ReactTextFormat allowedFormats={['URL']} linkDecorator={customLinkDecorator}>
                                {htmlToReactParser.parse(decodeURIComponent(message.content.message))}
                              </ReactTextFormat>
                            </StyledMessageText>
                            <Box display="flex" justifyContent="flex-end">
                              <Box
                                display="flex"
                                justifyContent={message.edited ? 'space-between' : 'flex-end'}
                                width="100%"
                              >
                                {message.edited && <StyledEditText>Edited</StyledEditText>}
                                {/* 
                                  **Commenting out the option to edit and Delete for now until ready to re-release
                                  <ContextualMessageMenu
                                    getTimeCreated={getTimeCreated}
                                    setShowEdit={setShowEdit}
                                    setEditMessageId={setEditMessageId}
                                    setShowDelete={setShowDelete}
                                    setDeleteMessageId={setDeleteMessageId}
                                    message={message}
                                  /> */}
                              </Box>
                            </Box>
                          </RightStyledReceivedMessage>
                        </StyledRow>
                      </>
                    )
                  }
                })}
              </>
            )
          })}
        </StyledMessageList>
      </StyledConversationContainer>

      <ConfirmModal
        title=""
        message="You cannot send more than 3 messages in 5 secs"
        onClose={() => setDisablePopup(false)}
        open={disablePopup}
        onConfirm={() => setDisablePopup(false)}
      />
      <ConfirmModal
        title=""
        message="Are you sure you want to delete this message?"
        onClose={() => setShowDelete(false)}
        open={showDelete}
        onConfirm={() => handleDelete(deleteMessageId)}
        showCancel
        icon={<StyledLargeDeleteIcon />}
      />
    </>
  )
}
