import { BackendUser, UserRoles, LandingPageUser, LoginUser, PartnerUser, BackendPartner, UserSession } from 'common/types'
import { get, post, put } from 'hooks/use-http/useHttp'

export const fetchUser = async (id: string) => {
  const response: BackendUser = await get(`user/${id}`, { dieSilent: true })
  return response
}

export const fetchAnyUser = async (id: string) => {
  // This includes deleted users
  const response: BackendUser = await get(`user/any/${id}`, { dieSilent: true, useApiKey: true })
  return response
}

export const fetchUserRelatedPartner = async (partnerId: string) => {
  const response: BackendPartner = await get(`partner-profile/${partnerId}`, { dieSilent: true })
  return response
}

export const getUserWithEmail = async (email: string) => {
  const response: LandingPageUser = await get(`/user/${email}/search`, { dieSilent: true, useApiKey: true})
  return response
}

export const fetchUserWithApiKey = async (id: string) => {
  const response: BackendUser = await get(`user/${id}`, { dieSilent: true, useApiKey: true })
  return response
}

export const createUser = async (user: BackendUser) => {
  const response: BackendUser = await post('user', user, { dieSilent: true })
  return response
}

export const createUserWithApiKey = async (user: BackendUser) => {
  const response: UserSession = await post(`user`, user, { useApiKey: true })
  return response
}

export const createPartnerUserWithApiKey = async (user: BackendUser, partnerType?: string) => {
  const response: PartnerUser = await post(`partner-profile/type/${partnerType}`, user, { useApiKey: true })
  return response
}

export const updateUser = async (user: BackendUser) => {
  const response: BackendUser = await put(`user/${user.id}`, user, { dieSilent: true })
  return response
}

export const updateUserWithApiKey = async (user: BackendUser) => {
  const response: BackendUser = await put(`user/${user.id}`, user, { dieSilent: true, useApiKey: true })
  return response
}

export const loginUser = async (username: string, password: string, role: UserRoles) => {
  let response: LoginUser = { user: {user: null, partner: null},  responseMessage: '' }
  try {
    const session: UserSession = await put(
      `user/login`,
      { username: username, password: password, role: role },
      { useApiKey: true }
    )

      response.user.user = session.user
      response.accessToken = session.session.accessToken
    } catch (e) {
      response.responseMessage = typeof e === "string" ? e : undefined
    }

  return response
}

export const loginGoogleUser = async (user: BackendUser) => {
  let response: LoginUser = { user: {user: null, partner: null},  responseMessage: '' }
    const session: UserSession = await post(`admin/login/google`, user, { useApiKey: true })
    response.user.user = session.user
    response.accessToken = session.session.accessToken
  return response
}

export const loginUserWithToken = async (token: string) => {
  let response: LoginUser = { user: {user: null, partner: null},  responseMessage: '' }
  try {
    const session: UserSession = await put(`user/login/token`, { token: token }, { dieSilent: true, useApiKey: true })
    response.user.user = session.user
    response.accessToken = session.session.accessToken
    } catch (e) {
      response.responseMessage = typeof e === "string" ? e : undefined
    }
  return response
}

export const resetPassword = async (email: string) => {
  const response: BackendUser = await put(`user/reset`, { email: email }, { useApiKey: true })
  return response
}

export const changePassword = async (id: string,password: string, currentPassword?: string) => {
  const response: UserSession = await put(
    `user/${id}/change`,
    { currentPassword: currentPassword, password: password },
    { dieSilent: false }
  )
  return response
}

export const changePasswordWithApiKey = async (id: string,password: string, currentPassword?: string) => {
  const response: UserSession = await put(
    `user/${id}/change`,
    { currentPassword: currentPassword, password: password },
    { dieSilent: false, useApiKey: true}
  )
  return response
}

export const invite = async (id: string, emails: string[]) => {
  const response: BackendUser = await put(`user/${id}/invite`, emails)
  return response
}

export const onboardUser = async (id: string, values: any[]) => {
  const response: BackendUser = await put(`user/${id}/onboard`, values)
  return response
}

export const onboardPartner = async (id: string, values: any[]) => {
  const response: BackendUser = await put(`partner-profile/${id}/preferences`, values)
  return response
}

export const sendWelcomeEmail = async (id: string, user: BackendUser) => {
  const response = await put(`user/${id}/welcome`, user, { dieSilent: true, useApiKey: true })
  return response
}

export const fetchEngagementMetrics = async (userId: string) => {
  const response = await get(`/user/${userId}/engagement-metrics`)
  return response
}

export const logoutUser = async (userId: string) => {
  const response = await put(`/user/logout/${userId}`, { dieSilent: true, useApiKey: true })
  return response
}

export const sendAdminInvitationToNonObsidiMember = async (outsideUserEmail: string, backendUserId: string, companyName: string) => {
  const response = await put(`/user/${outsideUserEmail}/admin/${backendUserId}/company/${companyName}/send-invitation`, { dieSilent: false })
  return response
}

export const loginAppleUser = async (appleResponse: any) => {
  let response: LoginUser = { user: { user: null, partner: null }, responseMessage: '' }
  const session: UserSession = await post(`admin/auth/apple/web`, appleResponse, { useApiKey: true })
  response.user.user = session.user
  response.accessToken = session.session.accessToken
  return response
}

const createAccountDeletionVerificationCode = async (userId: string, reasonId?: string, otherReasonText?: string) => {
  const response = await put(`user/${userId}/send-verification/delete`, { reasonId : reasonId, otherReasonText : otherReasonText }, { dieSilent: true, useApiKey: true })
  return response
}

export const useUserApi = () => {
  return {
    fetchUser,
    getUserWithEmail,
    fetchUserWithApiKey,
    createUserWithApiKey,
    updateUser,
    updateUserWithApiKey,
    loginUser,
    loginGoogleUser,
    resetPassword,
    changePassword,
    onboardUser,
    onboardPartner,
    invite,
    sendWelcomeEmail,
    fetchEngagementMetrics,
    createPartnerUserWithApiKey,
    logoutUser,
    sendAdminInvitationToNonObsidiMember,
    loginAppleUser,
    fetchAnyUser,
    fetchUserRelatedPartner,
    createAccountDeletionVerificationCode,
    changePasswordWithApiKey,
    loginUserWithToken
  } as const
}
