import { INPUT_PLACEHOLDER } from 'common/types/message.types'
import styled, { css } from 'styled-components'
import { Box, Avatar, IconButton, Typography } from '@material-ui/core'
import { Close, ChatBubble, Delete, Done } from '@material-ui/icons'
import { ReactComponent as ContextIcon } from 'icons/more-horizontal.svg'
import { ReactComponent as DeleteIcon } from 'icons/trash-icon.svg'
import { ReactComponent as ChatIcon } from 'icons/chat.svg'
import { ReactComponent as EditIcon } from 'icons/edit-icon-small.svg'
import { PathName } from 'common/types/pathName.types'

const { MessageList, Message, Row, SendButton, MessageText } = require('@livechat/ui-kit')

export const StyledMessagingContainer = styled(Box)`
  & {
    --accent: ${props => props.theme.colors.accent};
    --error: ${props => props.theme.colors.error};
    --bgPrimary: ${props => props.theme.colors.bgPrimary};
  }

  &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    text-decoration: underline;
  }
`

export const StyledMessageIcon = styled(ChatIcon)<{ active?: boolean; pathname?: string; showNotification?: boolean }>`
  cursor: pointer;
  color: ${props => props.theme.colors.accent};
  && {
    @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      transform: scale(1);
    }
  }

  && {
    /* I am using css variables for passing colours to SVGs, so here I change the definition of one of the variables to show primary when selected and nothing when not */
    --accent: ${props => props.pathname === PathName.MESSAGES ? `${props.theme.colors.primary} !important` : props.theme.colors.accent};
    --error: ${props => props.theme.colors.error};
    --bgPrimary: ${props => props.theme.colors.bgPrimary};
  }

  && circle.show-notification {
    opacity: ${props => props.showNotification ? 1 : 0};
  }
`
export const AlertContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledClose = styled(Close)`
  && {
    display: flex;
    float: right;
    color: ${props => props.theme.colors.accent1};
  }
`

export const StyledEditClose = styled(Close)`
  && {
    display: flex;
    float: right;
    color: ${props => props.theme.colors.accent1};
  }
`

export const StyledEditDone = styled(Done)`
  && {
    /* display: flex;
   float: right; */
    color: ${props => props.theme.colors.secondary};
  }
`

export const StyledMessageContainer = styled(Box)<{ isDevice: boolean }>`
  height: 0;
  position: fixed;
  z-index: 1000;
  bottom: 4.375em;
  right: ${props => (props.isDevice ? '0px' : '19.6em')};
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
`

export const StyledMessageContainerInner = styled(Box)`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 430px;
  width: 380px;
  border-radius: 10px 10px 0 0;
  color: ${props => props.theme.colors.accent1};
  background-color: ${props => props.theme.colors.supplementary} !important;
  border: 1px ${props => props.theme.colors.supplementary} solid;
`

export const StyledJobDetailsDivider = styled(Box)<{ isDevice?: boolean }>`
  && {
    width: 95%;
    height: 0.0625rem;
    margin: ${({ isDevice }) => (isDevice ? '0 auto 2.81rem auto' : '0 auto 2.81rem auto')};
    background-color: ${({ theme }) => theme.colors.accent};
  }
`

export const StyledJobClosedText = styled(Typography)<{ isDevice?: boolean }>`
  && {
    font-size: ${({ isDevice }) => (isDevice ? '1.125rem' : '2.1875rem')};
    font-style: normal;
    font-weight: 700;
    line-height: 123.5%; /* 3.0875rem */
    letter-spacing: 0.01563rem;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: ${({ isDevice }) => (isDevice ? '2.38rem' : '3.56rem')};
    text-align: ${({ isDevice }) => (isDevice ? 'center' : 'start')};
  }
`
export const StyledHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  position: relative;
`
export const StyledHeaderDetails = styled(Box)`
  display: flex;
  white-space: nowrap;
  //overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
  padding: 4px;
  position: relative;
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.accent1} !important;
`
export const StyledHeaderIcon = styled(Box)`
  transition-duration: 167ms;
  margin-left: auto;
  flex-shrink: 0;
  opacity: 1;
  position: relative;
  display: flex;
`
export const StyledConversationContainer = styled(Box)<{ isDevice: boolean }>`
  flex-direction: column;
  overflow-y: ${props => (props.isDevice ? 'unset' : 'hidden')};
  && {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  flex: 4 0 0px;
  height: ${props => (props.isDevice ? 'calc(60vh - 100px)' : '')};
`
export const StyledFooter = styled(Box)`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.colors.supplementary1};
  border-radius: 0.43rem;
`
export const StyledFooterButton = styled(Box)<{ mode?: string }>`
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  flex-wrap: nowrap;
  margin-top: 0;
  padding: 0;
  display: flex;
  border-radius: 7px;
  background-color: ${props => props.theme.colors.accent} !important;
  cursor: pointer;
`
export const StyledTextArea = styled.input<{ isDevice?: boolean }>`
  background-color: ${props => props.theme.colors.supplementary1} !important;
  color: ${props => props.theme.colors.accent1};
  border: 0px;
  /* border-top:1px solid ${props => props.theme.colors.accent}; */
  resize: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  display: flex;
  align-items: center;
  width: 80%;
  :focus {
    outline: none !important;
  }
`

export const StyledTextBox = styled(Box)<{ singleLine?: boolean; width?: string; scrollBarColor?: string, isDevice?: boolean }>`
  && {
    max-height: ${props => (props?.isDevice ? '3.5rem' : '140px')};
    width: ${props => (props?.width ? props.width : '80%')};
    outline: none;
    overflow-y: auto;
    color: ${props => props.theme.colors.accent1};
    position: relative;
    cursor: text;
  }

  :focus {
    outline: none !important;
  }

  ${props =>
    props.singleLine
      ? `
      ::-webkit-scrollbar {
        width: 0.0;
        height: 0.0;
        background-color: transparent;
      }
  
      white-space: nowrap;
      overflow-x: hidden;
    `
      : `
    ::-webkit-scrollbar {
      width: 0.43em;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${props?.scrollBarColor ? props.scrollBarColor : '#0000001f'};
      outline: 1px solid transparent;
    }
    `}

  ::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '${props => props.placeholder ?? INPUT_PLACEHOLDER}';
    color: ${props => props.theme.colors.accent};
    font-size: 0.86rem;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  :focus::before,
  &&:not(:empty)::before {
    content: '';
  }
`

export const StyledMessageList = styled(MessageList)<{ isDevice: boolean }>`
  background-color: ${props =>
    props.isDevice ? props.theme.colors.supplementary : props.theme.colors.supplementary} !important;
  color: ${props => props.theme.colors.accent1};
  padding: 0.5em;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const StyledAvatar = styled(Avatar)`
  margin-top: 6px;
  height: 30px !important;
  width: 30px !important;
`
export const StyledTitleAvatar = styled(Avatar)`
  height: 40px !important;
  width: 40px !important;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.accent1};
`

export const StyledContextIcon = styled(ContextIcon)`
  && {
    height: 0.6em;
    color: ${props => props.theme.colors.accent};
    padding: 0px;
    /* font-size: ${props => props.fontSize}rem; */
  }
`
export const StyledEditIcon = styled(EditIcon)`
  && {
    fill: ${props => props.theme.colors.accent1};
    width: 0.8em;
    height: 0.8em;
  }
`
export const StyledContextIconButton = styled(IconButton)``
export const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    fill: ${props => props.theme.colors.accent1};
    width: 0.8em;
    height: 0.8em;
  }
`

export const StyledSendButton = styled(SendButton)`
  && {
    svg {
      height: 20px !important;
      width: 20px !important;
      g {
        stroke: ${props => props.theme.colors.primary} !important;
        fill: ${props => props.theme.colors.primary} !important;
      }
      polygon {
        stroke: ${props => props.theme.colors.primary} !important;
        fill: ${props => props.theme.colors.primary} !important;
      }
    }
  }
`

export const StyledSendMessage = styled(Message)`
  div {
    padding: 0em;
    word-break: break-word;
    max-width: 100%;
  }
  span {
    color: ${props => props.theme.colors.accent1};
    font-weight: bold;
    font-size: 14px;
  }
`
export const LeftStyledReceivedMessage = styled(Box)<{ isDevice: boolean }>`
  background-color: ${props =>
    props.isDevice ? props.theme.colors.supplementary1 : props.theme.colors.supplementary1};
  div {
    padding: 0em;
    word-break: break-word;
    max-width: 100%;
  }
  span {
    color: ${props => props.theme.colors.accent1};
    font-weight: bold;
    font-size: 14px;
  }
  border-radius: 1rem;
  border-bottom-left-radius: 0rem;
  /* padding-left: 1rem; */
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0.34rem 1rem;
  flex-basis: auto;
`

export const RightStyledReceivedMessage = styled(Box)<{ isDevice: boolean }>`
  background-color: ${props =>
    props.isDevice ? props.theme.colors.supplementary1 : props.theme.colors.supplementary1};
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  div {
    padding: 0em;
    word-break: break-word;
  }
  span {
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
  }
  .lc-pxq2yy {
    width: 80%;
  }
  border-radius: 1rem;
  border-bottom-right-radius: 0rem;
  padding: 0.34rem 1rem;
`

export const StyledRow = styled(Row)`
  column-gap: 0.5em;
  padding-bottom: 5px;
`

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  color: ${props => props.theme.colors.primary} !important;
  :disabled {
    color: ${props => props.theme.colors.accent} !important;
    cursor: default;
    background-color: red;
  }

  && {
    padding: 7px;
  }
`

export const StyledMessageText = styled(MessageText)`
  align-self: flex-end;
  min-width: 7rem;
`

export const StyledEditText = styled.span`
  && {
    font-size: 10px;
    color: ${props => props.theme.colors.secondary};
    display: flex;
    align-items: center;
    font-weight: normal;
  }
`

export const StyledFooterContainer = styled(Box)<{ isDevice: boolean }>`
  width: 100%;
  padding: 1rem 2rem;
  border-top: 1px solid ${props => props.theme.colors.supplementary1};
  padding: 0.34rem 1rem;
`
export const StyledLargeDeleteIcon = styled(Delete)`
  && {
    fill: ${props => props.theme.colors.accent1};
  }
`

export const StyledEditActionContainer = styled(Box)`
  && {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`
