import { useState, useContext, useEffect } from 'react'
import { useNotification } from 'hooks/use-notification/useNotification'
import AuthContext from 'contexts/auth/AuthProvider'
import { BackendUser, UserRoles, PartnerType, UserSession, RegistrationTypes } from 'common/types'
import { useUserApi } from 'hooks/use-user-api/useUserApi'
import { useHistory } from 'react-router'
import { useAnalytics } from 'hooks/use-analytics/useAnalytics'
import { useQuery } from 'hooks/use-query/useQuery'
import { clearItem, useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import { usePreferenceApi } from 'hooks/use-preference-api/usePreferenceApi'
import { useThemeSwitch } from 'hooks/theme'
import { useSecurity } from 'hooks/use-security/useSecurity'
import { signupAlertState } from 'models/sign-up-alert/signup-alert.store'
import { useLocation } from 'react-router-dom'
import { useUserRedirect } from './useUserRedirect'
import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'

const redirect_uri = process.env.REACT_APP_REDIRECT_URI
const client_id = process.env.REACT_APP_CLIENT_ID
const slack_team = process.env.REACT_APP_SLACK_TEAM
const slack_client_id = process.env.REACT_APP_SLACK_CLIENT_ID

export const useAuth = () => {
  const { pathname } = useLocation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showLinkedinModal, setShowLinkedInModal] = useState(false)
  const {
    backendUser,
    backendPartner,
    logout,
    isAuthenticated,
    setIsAuthenticated,
    setUser,
    setPartner,
    clearUser,
    setClearSessions,
    forwardingUrl,
    setForwardingUrl,
    userPreference,
    setPreference,
    backendUserFunctions,
    setUserFunctions,
    backendUsageSummary,
    setUsageSummary,
    subscriptionType
  } = useContext(AuthContext)
  const { storeToken } = useSecurity()
  const { notify } = useNotification()
  const {
    loginUser,
    loginGoogleUser,
    createUserWithApiKey,
    resetPassword,
    loginAppleUser,
    fetchUserRelatedPartner
  } = useUserApi()
  const history = useHistory()
  const { logEventAnalytics } = useAnalytics()
  const { fetchPreferenceByUser, fetchPreferenceByUserWithApiKey } = usePreferenceApi()
  const { setStoredTheme } = useThemeSwitch()
  const { setItem, getItem } = useLocalStorage()
  const query = useQuery()
  const forwardUrl = query.get('forwardRef')
  const partnerPlan = query.get('partnerType')
  const partnerConfig = JSON.parse(getItem('partnerconfig'))
  const partnerType = partnerConfig?.packageType ? PartnerType.Corporate : PartnerType.Community
  
  const { redirectUser } = useUserRedirect()

  useEffect(() => {
    if (forwardUrl !== null) {
      setItem('forwardUrl', forwardUrl)
    }
  }, [])

  // TODO: remove role as argument. just use UserRoles where needed instead
  const loginUsingSSO = async (
    type: string,
    role: string,
    params?: any,
    registrationType?: string,
    callback?: () => void
  ) => {
    switch (type) {
      case 'linkedin':
        if (registrationType && registrationType === RegistrationTypes.Bfutr) {
          await setForwardingUrl(`/register/${registrationType}`)
        }
        window.location.assign(
          `https://www.linkedin.com/oauth/v2/authorization?scope=r_liteprofile%20r_emailaddress%20&response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}/linkedin/${role}`
        )
        logEventAnalytics('User', 'Log In', 'both', 'LinkedIN Login', { [MIXPANEL_PROPERTIES?.LOGIN_TYPE]: 'LinkedIn' })
        break
      case 'slack':
        if (registrationType && registrationType === RegistrationTypes.Bfutr) {
          await setForwardingUrl(`/register/${registrationType}`)
        }
        window.location.assign(
          `https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team,identity.avatar&client_id=${slack_client_id}&redirect_uri=${redirect_uri}/slack/${role}&team=${slack_team}`
        )
        logEventAnalytics('User', 'Log In', 'both', 'Slack Login', { [MIXPANEL_PROPERTIES?.LOGIN_TYPE]: 'Slack' })
        break
      case 'google':
        const { profileObj, tokenObj } = params

        if (profileObj) {
          const newUser: BackendUser = {
            username: profileObj?.email?.toLowerCase(),
            password: '',
            enabled: true,
            authType: 'sso',
            cognitoId: '',
            isOnBoarded: false,
            role: role as UserRoles,
            firstName: profileObj?.givenName,
            lastName: profileObj?.familyName,
            ssoType: 'google',
            image: profileObj?.imageUrl,
            verificationLink: '',
            welcomed: false,
            signoutRequested: false
          }
          const userSession = await loginGoogleUser(newUser)

          const user = userSession.user.user
          const token = userSession.accessToken

          if (user) {
            setUser(user)
            if (token) {
              storeToken(token)
            }
            setIsAuthenticated(true)
            const preferences = await fetchPreferenceByUser(user?.id)
            if (preferences) {
              setPreference(preferences)
            }
            if (preferences && preferences.darkMode !== null) {
              setStoredTheme(preferences.darkMode)
            }
            if (callback) {
              callback()
            } else if (user.isOnBoarded && JSON.parse(getItem('partnerconfig'))) {
              redirectUser(user, partnerConfig, partnerType)
            } else if (user.isOnBoarded) {
              history.push(forwardingUrl ? forwardingUrl.url : '/feed')
              setForwardingUrl(null)
            } else {
              if (pathname === '/') {
                history.push({ pathname: '/signup', state: location?.pathname })
                return
              }

              setItem('currentStep', '1')
              return
            }
            logEventAnalytics(
              'User',
              'Log In',
              'both',
              'Google Login',
              { [MIXPANEL_PROPERTIES?.LOGIN_TYPE]: 'Google', Email: user.username, [MIXPANEL_PROPERTIES?.ACCOUNT_TYPE]: user.role },
              user.id,
              {
                $name: `${user.firstName} ${user.lastName}`,
                $email: user.username
              }
            )
            return
          }
        } else {
          //if (params.error !== 'popup_closed_by_user' || 'idpiframe_initialization_failed')
          //notify('error', params.error)
        }
        break
      case 'apple':
        const { authorization } = params
        if (authorization) {
          const userSession = await loginAppleUser(authorization)
          const user = userSession.user.user
          const token = userSession.accessToken
          if (user) {
            setUser(user)
            if (token) {
              storeToken(token)
            }
            setIsAuthenticated(true)
            const preferences = await fetchPreferenceByUserWithApiKey(user?.id)
            if (preferences) {
              setPreference(preferences)
            }
            if (preferences && preferences.darkMode !== null) {
              setStoredTheme(preferences.darkMode)
            }
            if (callback) {
              callback()
            } else if (user.isOnBoarded && JSON.parse(getItem('partnerconfig'))) {
              redirectUser(user, partnerConfig, partnerType)
              return
            } else if (user.isOnBoarded) {
              history.push(forwardingUrl ? forwardingUrl.url : '/feed')
              setForwardingUrl(null)
            } else {
              if (pathname === '/') {
                history.push({ pathname: '/signup', state: location?.pathname })
                return
              }
              setItem('currentStep', '1')
              return
            }

            // logEventAnalytics(
            //   'User',
            //   'Log In',
            //   'both',
            //   'Apple Login',
            //   { 'Login Type': 'Apple', Email: user.username, 'Account type': user.role },
            //   user.id,
            //   {
            //     $name: `${user.firstName} ${user.lastName}`,
            //     $email: user.username
            //   }
            // )
            return
          }
        }
    }
  }

  const loginUsingUsernameAndPassword = async (role: UserRoles, callback?: () => void) => {
    const loginInfo = await loginUser(username?.toLowerCase(), password, role)
    const user = loginInfo.user?.user
    const token = loginInfo.accessToken

    if (!user) {
      return
    }
    if (user) {
      setUser(user)
      if (token) {
        storeToken(token)
      }
      setIsAuthenticated(true)
      const preferences = await fetchPreferenceByUser(user?.id)
      if (preferences) {
        setPreference(preferences)
      }
      if (preferences && preferences.darkMode !== null) {
        setStoredTheme(preferences.darkMode)
      }

      const partnerLog = {}
      const userLog = {}
      userLog[MIXPANEL_PROPERTIES?.USER_ID] = user?.id

      if (user?.partnerId) {
        const loggedInPartner = await fetchUserRelatedPartner(user?.partnerId)

        partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = loggedInPartner?.id
        partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = loggedInPartner?.name
        partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
      } else {
        userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = user?.role
      }

      logEventAnalytics(
        user?.partnerId ? 'Partner' : user?.role,
        `Log In`,
        'both',
        'login with username',
        { [MIXPANEL_PROPERTIES?.LOGIN_TYPE]: 'Email', Email: user.username, ...partnerLog, ...userLog },
        user.id,
        {
          $name: `${user.firstName} ${user.lastName}`,
          $email: user.username
        }
      )

      if (callback) {
        callback()
      } else if (user.isOnBoarded && JSON.parse(getItem('partnerconfig'))) {
        redirectUser(user, partnerConfig, partnerType)
      } else if (user.isOnBoarded) {
        history.push(forwardingUrl ? forwardingUrl?.url : '/feed')
        setForwardingUrl(null)
        return
      } else if (!user.isOnBoarded && location.pathname === '/') {
        setItem('currentStep', '1')
        history.replace('/signup')
        return
      } else {
        setItem('currentStep', '1')
        history.replace('/signup')
        return
      }
    }
  }

  const getPartnerType = () => {
    const partner = JSON.parse(getItem('partnerconfig') || '')
    if (partnerConfig.community === 'true') return PartnerType.Community
    return PartnerType.Corporate
  }

  const activateUser = (key: string) => {
    //TODO: set user to enabled
  }

  const signUpUsingUsernameAndPassword = async (role: UserRoles) => {
    const { setIsOTPSent } = signupAlertState

    const newUser: BackendUser = {
      username: username?.toLowerCase(),
      password: password,
      enabled: false,
      authType: 'creds',
      cognitoId: '',
      isOnBoarded: false,
      role: role,
      firstName: '',
      lastName: '',
      ssoType: '',
      verificationLink: '',
      welcomed: false,
      signoutRequested: false
    }

    const newUserSession: UserSession = (await createUserWithApiKey(newUser)) as any
    const user = newUserSession.user
    const token = newUserSession.session.accessToken
    if (user) {
      if (token) {
        storeToken(token)
      }

      setIsOTPSent(true)
      if (!partnerPlan) {
        logEventAnalytics(
          'User',
          `Account created`,
          'both',
          'new member acquisition',
          { 'Account Type': user?.role, Email: user?.username, 'Social Login type': 'Email' },
          user?.id,
          {
            $name: `${user?.firstName} ${user?.lastName}`,
            $email: user?.username
          }
        )
      }
    }
    return user
  }

  const onLogout = (signOut?: boolean) => {
    window.location.replace('https://obsidi.com/')
    logout(signOut)
    logEventAnalytics('User', `Log out`, 'both', 'Log out', { Email: backendUser?.username }, backendUser?.id, {
      $name: `${backendUser?.firstName} ${backendUser?.lastName}`,
      $email: backendUser?.username
    })
    clearUser()
    setIsAuthenticated(false)
    setPreference(null)
    clearItem('showNewEventsBadge')
  }

  const onPasswordReset = async (email: string) => {
    await resetPassword(email)
  }

  return {
    username,
    password,
    backendUser,
    backendPartner,
    isAuthenticated,
    setUsername,
    setPassword,
    setUser,
    setPartner,
    setIsAuthenticated,
    clearUser,
    loginUsingUsernameAndPassword,
    signUpUsingUsernameAndPassword,
    loginUsingSSO,
    onLogout,
    resetPassword,
    activateUser,
    showLinkedinModal,
    setShowLinkedInModal,
    onPasswordReset,
    forwardingUrl,
    setForwardingUrl,
    setClearSessions,
    userPreference,
    setPreference,
    backendUserFunctions,
    setUserFunctions,
    backendUsageSummary,
    setUsageSummary,
    subscriptionType
  } as const
}
