import { useMedia } from 'hooks/use-media/useMedia'
import { FunctionComponent, useEffect, useRef } from 'react'
//@ts-ignore
import { Box, Typography } from '@material-ui/core'
import { useAnalytics } from 'hooks/use-analytics/useAnalytics'
import { ReactComponent as MusicNote } from 'icons/music-note.svg'
import { observer } from 'mobx-react'
import { musicPlayerState } from 'models/music-player/music-player.store'
import { useAuth } from 'pages/auth/hooks/useAuth'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import styled from 'styled-components/macro'
import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'

const MusicNoteIcon = styled(MusicNote)`
  stroke: ${props => props.theme.colors.primary};
  fill: ${props => props.theme.colors.primary};
  /* height: 2em;
  width: 2em; */
`

const MediaContainer = styled(Box)`
  && {
    position: fixed;
    bottom: 96px;
    left: 16px;
    z-index: 5;
    display: none;
    flex-direction: column;
    align-items: center;
    opacity: 70%;
    :hover {
      opacity: 100%;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      right: unset;
      left: 16px;
    }
    .rhap_container {
      border-radius: 10px;
      border: 3px solid ${props => props.theme.colors.primary};
      box-shadow: 0 0.25rem 0.5rem 0 rgb(0 0 0 / 87%), 0 0.375rem 1.25rem 0 rgb(216 221 230 / 50%);
      background-color: transparent !important;
    }
    .rhap_main-controls-button {
      margin: 0 3px;
      color: ${props => props.theme.colors.primary};
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
    .rhap_volume-button {
      flex: 0 0 20px;
      font-size: 20px;
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.primary};
      margin-right: 6px;
    }
    .rhap_volume-bar-area {
      display: none;
      align-items: center;
      width: 100%;
      height: 14px;
      cursor: pointer;
    }
    svg {
      stroke: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary} !important;
    }
  }
`

export const StyledMediaPlayer: FunctionComponent = observer(() => {
  const { currentSong, getNewSong, pause, setItem, isDevice, showMusic, clearItem } = useMedia()
  const { logEventAnalytics } = useAnalytics()
  const { location } = useHistory()
  const theme = useTheme()
  const { backendUser, backendPartner } = useAuth()
  const CustomIcons = {
    play: <MusicNoteIcon />
  }
  const player = useRef<AudioPlayer>()

  const { paused, setPaused } = musicPlayerState

  useEffect(() => {
    if (player?.current) {
      setPaused(paused)
    }
  }, [player])

  useEffect(() => {
    const aud = player?.current?.audio?.current

    if (aud) {
      if (paused) {
        aud.pause()
      } else {
        aud.play()
      }
    }
  }, [paused])

  const checkIfPaused = e => {
    const partnerLog = {}
    const userLog = {}
    userLog[MIXPANEL_PROPERTIES?.USER_ID] = backendUser?.id
    if (backendUser?.partnerId) {
      partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
      partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
      partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
    } else {
      userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = backendUser?.role
    }
    switch (e?.type) {
      case 'pause':
        setItem('musicPaused', true)
        logEventAnalytics(
          backendUser?.partnerId ? 'Partner' : backendUser?.role,
          `Music Paused`,
          'both',
          'Pause Music',
          {
            ...partnerLog,
            ...userLog
          }
        )
        break
      case 'play':
        setItem('musicPaused', false)
        clearItem('musicPaused')
        logEventAnalytics(
          backendUser?.partnerId ? 'Partner' : backendUser?.role,
          `Music Played`,
          'both',
          'Play Music',
          {
            ...partnerLog,
            ...userLog
          }
        )
        break
    }
  }
  if (
    (backendUser?.welcomed && !backendUser?.isOnBoarded) ||
    (!backendUser?.welcomed && !!!backendUser?.partnerId) ||
    (isDevice && location.pathname.includes('/channel/'))
  ) {
    return <></>
  }
  return (
    <>
      {
        <MediaContainer>
          {showMusic && (
            <AudioPlayer
              volume={1}
              customProgressBarSection={[]}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customAdditionalControls={[]}
              customIcons={CustomIcons}
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              layout="horizontal"
              src={currentSong}
              onPause={e => checkIfPaused(e)}
              onPlay={e => checkIfPaused(e)}
              onClickNext={pause ? null : getNewSong}
              onEnded={pause ? null : getNewSong}
              ref={player}
            />
          )}

          {showMusic && (
            <Typography variant="caption">
              <Box fontStyle="italic" fontWeight="bold" color={theme.colors.secondary} textAlign="center">
                vibe with us!!!
              </Box>
            </Typography>
          )}
        </MediaContainer>
      }
    </>
  )
})
