import { Context, createContext, Dispatch, FunctionComponent, SetStateAction, useState } from "react";

interface IAdminSideNav {
  isUserMenuOpen: boolean
  setUserMenuOpen: Function
  isEventMenuOpen: boolean
  setEventMenuOpen: Function
  isProductMenuOpen: boolean
  setProductMenuOpen: Function
  isMemberManagementMenuOpen: boolean
  setMemberManagementMenuOpen: Function
  isUserGroupManagementMenuOpen: boolean
  setUserGroupManagementMenuOpen: Dispatch<SetStateAction<boolean>>
  isTicketpManagementMenuOpen: boolean
  setTicketpManagementMenuOpen: Dispatch<SetStateAction<boolean>>
  active: number
  setActive: Function
}

export const AdminSideNavContext: Context<IAdminSideNav> = createContext<IAdminSideNav>({
  isUserMenuOpen: false,
  setUserMenuOpen: (userMenu: boolean) => {},
  isEventMenuOpen: false,
  setEventMenuOpen: (eventMenu: boolean) => {},
  isProductMenuOpen: false,
  isMemberManagementMenuOpen: false,
  setProductMenuOpen: (eventMenu: boolean) => {},
  setMemberManagementMenuOpen: (eventMenu: boolean) => {},
  isUserGroupManagementMenuOpen: false,
  setUserGroupManagementMenuOpen: (groupMenu: boolean) => {},
  isTicketpManagementMenuOpen: false,
  setTicketpManagementMenuOpen: (ticketMenu: boolean) => {},
  active: 0,
  setActive: (active: number) => {}
})

export const AdminSideNavProvider:FunctionComponent = ({children}) => {
  const [isUserMenuOpen, setUserMenuOpen] = useState(false)
  const [isEventMenuOpen, setEventMenuOpen] = useState(false)
  const [isProductMenuOpen, setProductMenuOpen] = useState(false)
  const [isMemberManagementMenuOpen, setMemberManagementMenuOpen] = useState(false)
  const [isUserGroupManagementMenuOpen, setUserGroupManagementMenuOpen] = useState(false)
  const [isTicketpManagementMenuOpen, setTicketpManagementMenuOpen] = useState(false)
  const [active, setActive] = useState()

  return (
    <AdminSideNavContext.Provider
      value={{
        isEventMenuOpen,
        isUserMenuOpen,
        isProductMenuOpen,
        isMemberManagementMenuOpen,
        isUserGroupManagementMenuOpen,
        isTicketpManagementMenuOpen,
        setEventMenuOpen,
        setUserMenuOpen,
        setProductMenuOpen,
        setMemberManagementMenuOpen,
        setUserGroupManagementMenuOpen,
        setTicketpManagementMenuOpen,
        setActive,
        active
      }}
    >
      {children}
    </AdminSideNavContext.Provider>
  )
}