import { makeAutoObservable } from "mobx";

export class MusicPlayerStore {
  paused = true

  constructor() {
    makeAutoObservable(this)
  }

  setPaused = (paused: boolean) => {
    this.paused = paused
  }

  togglePaused = () => {
    this.setPaused(!this.paused)
  }
}

export const musicPlayerState = new MusicPlayerStore