import { Box } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { BackendUser } from 'common/types'
import { Receiver } from 'common/types/message.types'
import { processText } from 'common/utils/processTextDM'
import { FunctionComponent } from 'react'
import {
  StyledEditActionContainer,
  StyledEditClose,
  StyledEditDone,
  StyledFooter,
  StyledIconButton,
  StyledTextBox
} from './style'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'

interface SubmitMessageProps {
  showEdit: boolean
  typedMessage: string
  handleChange: (e: any) => void
  isDevice: boolean
  sender: BackendUser
  receiver: Receiver
  handleSubmit: Function
  setShowEdit: Function
  isConnection: boolean
  hasMessagePermission: boolean
  theme: any
  setEditedMessage?: Function
  editMessageId?: string
  editMessage?: Function
  maxDMCount: boolean
  receiverMaxDMCount: boolean
}

export const SubmitMessage: FunctionComponent<SubmitMessageProps> = ({
  showEdit,
  setShowEdit,
  typedMessage,
  sender,
  receiver,
  handleSubmit,
  isConnection,
  hasMessagePermission,
  theme,
  maxDMCount,
  receiverMaxDMCount
}) => {
  const { isDevice } = useScreenSizes()

  const canMessage = isConnection || hasMessagePermission

  const textFieldId = 'textareaElement'

  const charactersLength = 1500

  const renderInput = () => {
    if (maxDMCount) {
      return (
        <Box textAlign="center" color={theme.colors.error} padding="1rem 0.3125rem">
          You have used up your DM subscription count for the month
        </Box>
      )
    } else if (receiverMaxDMCount) {
      return (
        <Box textAlign="center" color={theme.colors.error} padding="1rem 0.3125rem">
          You no longer have permission to chat with this user
        </Box>
      )
    } else if (canMessage) {
      return (
        <>
          <StyledTextBox
            dangerouslySetInnerHTML={{ __html: showEdit ? typedMessage : '' }}
            isDevice={isDevice}
            id={textFieldId}
            contentEditable
            onKeyDown={e => {
              const target = e.currentTarget

              if (target.innerText.length >= charactersLength) {
                if (e.keyCode !== 8) {
                  e.preventDefault()
                }
              }
            }}
            onKeyUp={e => {
              const elem = document.getElementById('send-btn')

              if (e.currentTarget.innerText.length) {
                if (elem) {
                  elem.style.fill = theme.colors.primary
                }
              } else {
                if (elem) {
                  elem.style.fill = theme.colors.accent
                }
              }
            }}
            onPaste={e => {
              e.preventDefault()

              // @ts-ignore
              const text = (e.clipboardData || window.clipboardData).getData('text')
              const selection = window.getSelection()

              if (!selection.rangeCount) {
                return false
              }

              selection.deleteFromDocument()
              selection
                .getRangeAt(0)
                .insertNode(document.createTextNode(text.slice(0, charactersLength - e.currentTarget.innerText.length)))
              selection.getRangeAt(0).collapse(false)
            }}
          />
          <Box>
            {showEdit ? (
              <Box display="flex" justifyContent="center">
                <StyledEditActionContainer
                  onClick={() => {
                    setShowEdit(false)
                  }}
                >
                  <StyledEditClose />
                </StyledEditActionContainer>
                <StyledEditActionContainer
                  onClick={() => {
                    const elem = document.getElementById(textFieldId)
                    const text = processText(elem?.innerHTML)

                    handleSubmit(text)
                  }}
                >
                  <StyledEditDone />
                </StyledEditActionContainer>
              </Box>
            ) : (
              <StyledIconButton
                onClick={() => {
                  const text = processText(document.getElementById(textFieldId)?.innerHTML)

                  document.getElementById(textFieldId).innerHTML = ''

                  const elem = document.getElementById('send-btn')
                  if (elem) {
                    elem.style.fill = theme.colors.accent
                  }

                  handleSubmit(text)
                }}
                style={{
                  backgroundColor: `${theme.colors.supplementary1}`,
                  borderRadius: '0.3rem'
                }}
              >
                <Send
                  id="send-btn"
                  style={{
                    fill: theme.colors.accent
                  }}
                />
              </StyledIconButton>
            )}
          </Box>
        </>
      )
    } else {
      return (
        <Box textAlign="center" color={theme.colors.error} padding="1rem 0.3125rem">
          You no longer have permission to chat with this user
        </Box>
      )
    }
  }

  return (
    <StyledFooter
      style={{
        padding: '0.4rem 0.5rem',
        justifyContent: 'space-between'
      }}
    >
      {renderInput()}
    </StyledFooter>
  )
}
