import { Box, Divider, Button } from '@material-ui/core'
import styled from 'styled-components/macro'
import { bfutr2023palette } from './bfutr2023palette'
import { ReactComponent as TopLeftQuarterCircle } from './assets/TopLeftQuarterCircle.svg'
import { ReactComponent as TopRightQuarterCircle } from './assets/TopRightQuarterCircle.svg'
import { ReactComponent as BottomLeftQuarterCircle } from './assets/BottomLeftQuarterCircle.svg'
import { ReactComponent as BottomRightQuarterCircle } from './assets/BottomRightQuarterCircle.svg'
import { ReactComponent as ArrowBack } from './assets/Arrrow-back.svg'
import { ReactComponent as BottomRip } from './assets/Rip2_section1.svg'
import { ReactComponent as Arrow } from './assets/Inside_CTA_arrow.svg'

export type Size = 'sm' | 'md' | 'lg'

const getModalBodySize = (size?: Size) => {
  switch (size) {
    case 'sm':
      return '40%'
    case 'md':
      return '65%'
    case 'lg':
      return '90%'
    default:
      return '90%'
  }
}

export const StyledPrimaryButton = styled(Button)<{radius?: boolean}>`
  && {
    background: ${bfutr2023palette.primaryGradient};
    font-weight: bold;
    font-size: 20px;
    min-height: 54px;
    border-radius: ${props => (props.radius ? '40px' :  'inherited')};
    &:hover {
      background: ${bfutr2023palette.primaryGradient};
    }
    &:disabled {
      background: ${bfutr2023palette.disabledButton};
    }
  }
`

export const LandingBox = styled(Box)`
  && {
    background: ${bfutr2023palette.darkBackground};
    height: 100vh;
    width: 100%;
  }
`

export const LandingHeaderBox = styled(Box)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  color: #ffffff;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 30px;
    line-height: 40px;
  }
`

export const StyledLandingHeaderSpan = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  background: ${bfutr2023palette.primaryGradient};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 30px;
    line-height: 40px;
  }
`

export const StyledTopLeftQuarterCircle = styled(TopLeftQuarterCircle)`
  height: 6em;
  width: 6em;
  position: absolute;
`

export const StyledTopRightQuarterCircle = styled(TopRightQuarterCircle)`
  height: 6em;
  width: 6em;
  position: absolute;
  right: 0em;
`

export const StyledBottomLeftQuarterCircle = styled(BottomLeftQuarterCircle)`
  height: 6em;
  width: 6em;
  position: absolute;
  bottom: 0em;
`
export const StyledBottomRightQuarterCircle = styled(BottomRightQuarterCircle)`
  height: 6em;
  width: 6em;
  position: absolute;
  bottom: 0em;
  right: 0em;
`

export const StyledDescriptionBox = styled(Box)<{isDevice: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.isDevice ? 'center' :  'unset')};
  flex-direction: ${props => (props.isDevice ? 'column' :  'row')};
  width: 100%;
  background-color: ${bfutr2023palette.secondaryBackground};
  padding: ${props => (props.isDevice ? '5.33em 1em 0' : '5.33em 12em 0')};
  color: #FFFFFF;
`

export const DescriptionHeaderBox = styled(Box)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 52.5657px;
  line-height: 65px;
  background: ${bfutr2023palette.tealToPinkGradient};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
`

export const SectionHeaderBox = styled(Box)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 52.5657px;
  line-height: 65px;
  color: #FFFFFF;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 40px;
  }
`

export const AnimatedClapperBox = styled(Box)`
  @keyframes clap {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-19deg) translateY(-70px) translateX(3px);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-19deg) translateY(-70px) translateX(3px);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  width: 100%;
  animation: clap 6s ease-in-out infinite;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    @keyframes clap {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-19deg) translateY(-40px) translateX(3px);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-19deg) translateY(-40px) translateX(3px);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  }
`

export const StyledImage = styled.img`
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 50%;
  background-color: white;
  display: flex;
  margin: 0 auto;
`

  export const StyledArrowButton = styled(ArrowBack)`
  margin-right: 0.5em;
  height: 2em;
  width: 2em;
  `

export const StyledBox = styled(Box)`
  background: url('https://bptn-frontend-music-dev.s3.amazonaws.com/media/images/summit/see_all_partners_banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25em;
  width: 100%;
`

export const StyledDivider = styled(Divider)`
  && {
    background-color: ${props => props.theme.colors.accent1};
    margin: 1em auto;
  }
`

export const FlippedBottomRip = styled(BottomRip)`
  transform: rotate(180deg);
`
export const StyledSeeAllPartnersButton = styled(Button)`
  && {
    width: 272px;
    height: 59px;
    border-radius: 40px;
    background: ${bfutr2023palette.greenToYellowGradient};
    &:hover {
      background: ${bfutr2023palette.yellowToGreenGradient};
    }
    &:disabled {
      background: ${bfutr2023palette.DisabledColor};
    }
  }
`

export const StyledBecomeAPartnerButton = styled(Button)`
  && {
    width: 272px;
    height: 59px;
    border-radius: 40px;
    background: ${bfutr2023palette.lightBlueToPurpleGradient};
    &:hover {
      background: ${bfutr2023palette.purpleToLightBlueGradient};
    }
    &:disabled {
      background: ${bfutr2023palette.DisabledColor};
    }
  }
`

export const StyledArrowCircleRightRounded = styled(Arrow)`
  && {
    width: 21.67px;
    height: 21.67px;
    margin-left: 1rem;
  }
`

export const StyledPartnerButtonTextBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    color: black;
    font-weight: 700;
    font-size: 1.0625rem;
    font-family: 'Greycliff CF', sans-serif;;
  }
`
export const StyledPartnerSectionHeaderBox = styled(Box)`
  font-family: 'Montserrat', sans-serif;
  font-size: 52.57px;
  font-weight: 800;
  line-height: 65px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 25px;
    line-height: 31px;
  }
  background-color: #30bae9;
  background-image: linear-gradient(135deg, #30bae9 50%, #ad6bf5 54%);
`

export const StyledTypographyBox = styled(Box)`
  font-family: 'Greycliff CF', sans-serif;
`
