import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import { Loader } from 'components/core/loader'

import { SwitchWithError } from 'pages/common/SwitchWithError'

//@ts-ignore
const HomeRouter = Loadable({ loader: () => import('pages/dashboard/HomeRouter'), loading: Loader })

export const Router = () => {
  return (
    <SwitchWithError>
      <Route path="/">
        <HomeRouter />
      </Route>
    </SwitchWithError>
  )
}

export const preloadRoutes = () => {
  //GeneratorToken: <next-preload>
  HomeRouter.preload()
}
