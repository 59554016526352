import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'
import { StageSpinner as Spinner } from 'react-spinners-kit'

import { theme } from '../../../styles/globalstyles'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0;
`

interface LoaderProps {
  loading?: boolean
  color?: string
  size?: number
}

export const Loader: FunctionComponent<LoaderProps> = ({ loading, color }) => (
  <Container>
    <PlainLoader loading={loading} color={color} />
  </Container>
)

export const PlainLoader: FunctionComponent<LoaderProps> = ({ loading, size = 30, color }) => (
  <Spinner size={size} color={color || theme.colors.primary} loading={loading} />
)

