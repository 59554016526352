import styled from 'styled-components/macro'
import { Box, Dialog, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { ReactComponent as CircledCancel } from 'icons/circle-xmark-solid.svg'
import { Button } from 'common/atoms/Button/Button'

export type Size = 'sm' | 'md' | 'lg'

const getModalBodySize = (size?: Size) => {
  switch (size) {
    case 'sm':
      return '40%'
    case 'md':
      return '65%'
    case 'lg':
      return '90%'
    default:
      return '90%'
  }
}

//@ts-ignore
export const TopBox = styled(Box)<{ noborder: string; borderColor?: string }>`
  border-bottom: ${props =>
    props.noborder === 'true' ? 'none' : `1px solid ${props.borderColor} !important;`};
  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    border-bottom: ${props =>
      props.borderColor === 'default'
        ? 'none !important'
        : `1px solid ${props.borderColor} !important;`};
  }
`
export const GreyCloseIcon = styled(ClearIcon)`
  color: darkGrey;
  transform: scale(1);
`

export const CircledGreyCloseIcon = styled(CircledCancel)`
  height: 1.5em;
  width: 1.5em;
  transform: scale(1);
  cursor: pointer;
`
export const Title = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 900;
    color: #494b57;
  }
`

export const DialogInner = styled.div<{ noMargin?: boolean; backgroundColor?: string }>`
  outline: none;
  /* max-width: 540px; */

  width: auto;
  height: auto;
  padding: ${props => (props.noMargin ? '0' : '0 1.5em 1.5em 1.5em')};
  font-size: 0.75rem;
  border-radius: 15px;
  background-color: ${props => props.backgroundColor || props.theme.colors.supplementary};
  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
  }
`

export const ModalInner = styled.div<{ size?: Size }>`
  outline: none;
  max-width: ${props => (props.size === 'lg' ? '100%' : getModalBodySize(props.size))};
  width: 100%;
  font-size: 0.75rem;
  height: ${props => getModalBodySize(props.size)};
  border-radius: 6px;
  display: grid;
  grid-template-areas:
    'title'
    'body';
  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }
`
export const StyledDialogBox = styled(Box)`
  && {
    padding-bottom: 15px;
  }
`

export const StyledDialog = styled(Dialog)`
  && {
    overflow-y: hidden;
    .MuiDialog-paper {
      border-radius: 15px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .MuiDialogContent-root {
      padding: 0px;
      overflow: hidden;
      overflow-wrap: break-word;
      text-align: center;
      align-content: center;
      justify-content: center;
    }

    .MuiTypography-noWrap {
      white-space: wrap;
      text-align: center;
    }

    .MuiButton-label {
      font-weight: bolder;
    }
    .MuiDialogTitle-root {
      flex: 0 0 auto;
      margin: 0;
      padding: 5px 10px;
    }
  }
  &.MuiDialog-paper {
    overflow-y: hidden;
  }
`

export const StyledConfirmationButton = styled(Button)<{isDanger: boolean}>`
&& {
  background-color: ${props => props.isDanger ? `${props.theme.colors.error}` : ''}
}
`
