import { IChannel } from './channels.types';
import { QuestionAnswer } from "./question.types"
import { BackendUser } from './user.types';

export interface BackendPartner {
    id?: string
    name?: string
    type?: PartnerType
    email?: string
    description?: string
    logo?: string
    banner?: string
    preferences?: QuestionAnswer[]
    onboardingAnswers?: QuestionAnswer[]
    enabled?: boolean
    welcomed?: boolean
}

export enum PartnerType{
    Community = "community",
    Corporate = "corporate"
}

export interface BackendPartnerSubscription {
    id?: string
    partnerId: string
    subscriptionId?: string
    enabled?: boolean
    autoRenew?: boolean
    paymentRef?: string
}

export interface SubscribedPartner {
    partner: BackendPartner
    partnerSubscription: BackendPartnerSubscription
    user: BackendUser
}