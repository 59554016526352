export const getSentryConfig = () => {
  // Check if the app is running on localhost or 127.0.0.1
  const targetUrl = window.location.href;
  const isLocalhost = targetUrl.includes('localhost')

  let tracesSampleRate = 1.0
  let replaysSessionSampleRate = 1.0
  let replaysOnErrorSampleRate = 1.0

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
      tracesSampleRate = 1.0
      replaysSessionSampleRate = 1.0
      replaysOnErrorSampleRate = 1.0
      break
    case 'qa':
      tracesSampleRate = 0.5
      replaysSessionSampleRate = 0.5
      replaysOnErrorSampleRate = 1.0
      break
    case 'production':
      tracesSampleRate = 0.1
      replaysSessionSampleRate = 0.1
      replaysOnErrorSampleRate = 1.0
      break
    default:
      // Default to development settings if NODE_ENV is not set
      break
  }

  return {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    isLocalhost
  }
}
