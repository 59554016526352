import { FunctionComponent, ReactNode, forwardRef } from 'react'
import { Slide, Box, Backdrop, Fade, Dialog } from '@material-ui/core'
import { ModalInner, Size, GreyCloseIcon } from './styled'

//@ts-ignore
import { useTheme } from 'styled-components'
import styled from 'styled-components/macro'

const StyledDialog = styled(Dialog)`
  && {
    overflow-y: hidden;

    .MuiDialog-paper {
      border-radius: 15px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  &.MuiDialog-paper {
    overflow-y: hidden;
  }
`

const Transition = forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

interface MobileModalProps {
  onClose: () => void
  show: boolean
  title: ReactNode
  size?: Size
  showCancel?: boolean
}

export const MobileModal: FunctionComponent<MobileModalProps> = ({
  show,
  title,
  size,
  showCancel,
  children,
  onClose
}) => {
  const theme = useTheme()

  return (
    <StyledDialog
      fullScreen
      open={show}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      //@ts-ignore
      TransitionComponent={Transition}
    >
      <Box bgcolor={theme.colors.supplementary} color={theme.colors.primaryLight}>
        {/*
      //@ts-ignore*/}
        <Box display="flex" margin="0.5em 0.5em" padding="1em" onClick={onClose}>
          <Box display="flex" marginLeft="auto">
            {title}
          </Box>
          <Box display="flex" flexDirection="row-reverse" flexGrow={1}>
            {showCancel && <GreyCloseIcon />}
          </Box>
        </Box>
        <Fade in={show}>
          <ModalInner size={size}>{children}</ModalInner>
        </Fade>
      </Box>
    </StyledDialog>
  )
}
