export const bfutr2023palette = {
    primaryGradient: 'linear-gradient(90deg, #30CAC8 29.69%, #4D338D 100%)',
    primaryActive: '#571D76',
    baseText: '#343D4B',
    darkText: '#030106',
    greenText: '#21C53E',
    greyText: '#4B4F50',
    bubbleTabBackground: '#EBEBEB',
    orangeToRedVerticalGradient: 'linear-gradient(180deg, #FB8334 0%, #CF1259 100%)',
    purpleToOrangeVerticalGradient: 'linear-gradient(180deg, #9752BD 0%, #CF1259 100%)',
    backgroundColor: '#fff',
    selectBackground: '#E0E6ED',
    disabledButton: '#E9E9E9',
    secondary: '#1E1847',
    required: '#A0033C',
    darkBackground: '#0F1013',
    DisabledColor:'#E1E1E1',
    pinkToPurpleGradient: 'linear-gradient(90deg, #DA006A 0%, #72004D 30.06%, #7D1882 56.62%, #240563 100%)',
    greenToYellowGradient: 'linear-gradient(90deg, #21C53E 0%, #B6CC22 100%)',
    lightBlueToPurpleGradient: 'linear-gradient(90deg, #30CAC8 29.69%, #4D338D 100%)',
    purpleToLightBlueGradient: 'linear-gradient(90deg, #4D338D 29.69%, #30CAC8 100%)',
    yellowToGreenGradient: 'linear-gradient(90deg, #B6CC22 0%, #34C63B 100%)',
    secondaryBackground: '#0F5358',
    tealToPinkGradient: 'linear-gradient(270deg, #AD6BF5 21.03%, #30BAE9 43.93%)',
    secondaryGradient: 'linear-gradient(270deg, #AD6BF5 40%, #30BAE9 55%)',
    lightText: '#FFFFFF'
}