import { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
//@ts-ignore
import { useTheme } from 'styled-components'

const StyledIcon = styled(Box)`
 && {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
  border: 1px solid ${props => props.theme.colors.accent1};
  position: absolute;
  bottom: 0;
  right: -5%;
 }
`

interface StatusIconProps {
  connected: boolean
}

export const ConnectedStatusIcon: FunctionComponent<StatusIconProps> = ({ connected }) => {
  const theme = useTheme()
  
  return (
    <StyledIcon style={{backgroundColor: connected ? theme.colors.success : theme.colors.error}} />
  )
}