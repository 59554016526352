import { useEffect, useState, useContext } from 'react'
import { useReceiver } from '../../direct-messaging/hooks/useReceiver'
import { useAuth } from 'pages/auth/hooks/useAuth'
import { Message, Receiver } from 'common/types/message.types'
import { useMessageApi } from 'hooks/use-message-api/useMessageApi'

export const useChatsListItem = (
  message: Message,
  setOpenChat: Function,
  setReceiver: Function
) => {
  const { getReceiver } = useReceiver()
  const { backendUser } = useAuth()
  const { updateStatus } = useMessageApi()
  const [isLoading, setIsLoading] = useState(true)
  const [localReceiverInfo, setLocalReceiverInfo] = useState<Receiver>()
  const isBackendUserSender: boolean = message?.senderId == backendUser.id

  const openChatPopup = async (receiver: Receiver, messageId: string) => {
    setIsLoading(true)
    await getReceiver(receiver.id).then(receiver => {
      setReceiver(receiver)
      setOpenChat(true)
    })
    if (message.status === 'unread') {
        const updatedMessage = await updateStatus(messageId)
        if (updatedMessage) {
            message.status = updatedMessage.status
        }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    // TODO: if isOpen and receiver is part of the message then status should be read
    if (!localReceiverInfo) {
      getReceiver(message?.senderId == backendUser.id ? message?.receiverId : message?.senderId, false).then(
        receiver => {
          setIsLoading(false)
          setLocalReceiverInfo(receiver)
        }
      )
    }
  }, [localReceiverInfo])

  return {
    isLoading,
    openChatPopup,
    localReceiverInfo,
    isBackendUserSender
  }
}
