import { BackendPartner, BackendUser } from 'common/types'
import { logMixpanelEvent } from 'hooks/use-mixpanel/useMixpanel'
import ReactGA from 'react-ga'

export const DEFAULT_VALUE = 'undefined'

type LogType = 'GA' | 'mixpanel' | 'both'

export enum AnalyticUserType {
  MEMBER = 'MEMBER',
  PARTNER = 'PARTNER',
  ADMIN = 'ADMIN',
  MEMBER_PARTNER = 'MEMBER_PARTNER',
  ALL = 'ALL'
}

const isPartner = (user: BackendUser) => (!!user?.partnerId ? user?.role : 'Partner')

export const logEventAnalytics = (
  category: string,
  event: string,
  logType: LogType,
  label?: string,
  params?: any,
  identity?: string,
  userInfo?: any
) => {
  switch (logType) {
    case 'GA':
      ReactGA.event({
        category: category,
        action: event,
        label: label
      })
      break
    case 'mixpanel':
      logMixpanelEvent.identify(identity)
      logMixpanelEvent.track(event, params)
      logMixpanelEvent.people.set(userInfo)
      break
    case 'both':
      ReactGA.event({
        category: category,
        action: event,
        label: label
      })
      logMixpanelEvent.identify(identity)
      logMixpanelEvent.track(event, params)
      logMixpanelEvent.people.set(userInfo)
      break
  }
}

export const handleTracker = async <Data>(
  userType: AnalyticUserType,
  eventName: string,
  data: Data,
  logType: LogType,
  partner?: BackendPartner,
  user?: BackendUser
) => {
  switch (userType) {
    case AnalyticUserType.MEMBER:
      await handleAnalytics(userType, eventName, logType, data, user)
      break
    case AnalyticUserType.ADMIN:
      await handleAnalytics(userType, eventName, logType, data, user)
      break
    case AnalyticUserType.PARTNER:
      await handleAnalytics(userType, eventName, logType, data, user)
      break
    case AnalyticUserType.MEMBER_PARTNER:
      await handleAnalytics(userType, eventName, logType, data, user)
      break
    case AnalyticUserType.ALL:
      await handleAnalytics(userType, eventName, logType, data, user)
      break

    default:
      throw new Error('Not Implemented')
  }
}

const handleAnalytics = async <Data>(
  userType: AnalyticUserType,
  eventName: string,
  logType: LogType,
  data: Data,
  user: BackendUser
) => {
  await logEventAnalytics(
    userType === AnalyticUserType.MEMBER_PARTNER || userType === AnalyticUserType.ALL ? isPartner(user) : user?.role,
    eventName,
    logType,
    eventName,
    data,
    user?.id,
    {
      $name: `${user?.firstName} ${user?.lastName}`,
      $email: user?.username
    }
  )
}

export const useAnalytics = () => {
  return {
    logEventAnalytics,
    handleTracker
  } as const
}
