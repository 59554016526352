import { htmlToReactParser } from 'common/HTMLToReactParser'

export const formatMessage = (message: string, count = 40) => {
    if (message) {
        const result = decodeURIComponent(message)?.slice(0, count) + (message.length > count ? '...' : '')
        let decoded = ''
        try {
            decoded = result;
        } catch (e) {
            decoded = decodeURIComponent(message);
        }
        return htmlToReactParser.parse(decoded)
    }
    return ''
}