import { BackendPaginatedResponse } from '.'
import { BackendUser } from './user.types'


export interface IChannelResponse extends BackendPaginatedResponse<IChannel> { }
export interface IChannelResponse extends BackendPaginatedResponse<IChannel> { }

export interface IChannel {
  id: string
  name: string
  companyName: string
  description: string
  type: ChannelType
  status?: ChannelStatus
  socketId?: string
  partnerId?: string
  tags?: string[]
  metaData?: IChannelMetaData
  companyLogo: string
}

export interface IChannelUser {
  id: string
  channelId: string
  userId: string
  status: ChannelStatus
  socketId?: string
  partnerId?: string
  metaData?: IChannelUserMetaData
  firstName?: string
  lastName?: string
  image?: string
  headline?: string
  isAdmin?: boolean
}

export interface IChannelMessageResponse {
  id: string
  senderId: string
  sender: BackendUser
  channelId: string
  content: {
    message: string
  }
  createdAt: string
  updatedAt: string
  deletedAt: string
  sendDate: string
  readDate: string
  edited: boolean
  senderAvatar: string
}

export interface IChannelMessagesResponse {
  page: string
  pageSize: string
  dir: 'desc'
  sort: 'created_at'
  totalCount: string
  data: IChannelMessageResponse[]
}

export interface ICreateChannelPayload {
  name: string
  type: ChannelType
  tags: string[]
  partnerId?: string
  description?: string
  metaData?: IChannelMetaData
}

export interface ICreateChannelMessagePayload {
  channelId: string
  content: { message: string }
}

export interface ICreateChannelUsersPayload {
  connectionIds: string[]
}

export interface ICreateChannelResponse {
  channel: IChannel
  channelUser: IChannelUser
}

export interface IChannelMetaData {}
export interface IChannelUserMetaData { }

export enum ChannelType {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export enum ChannelStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  BLOCKED = 'blocked'
}

export const CHANNELS_INPUT_PLACEHOLDER = 'Start typing here...'

export enum ChannelMenuItems {
  ADD_MEMBER = 'Add Member(s)',
  VIEW_MEMBERS = 'View Members',
  NOTIFICATIONS = 'Notifications',
  ADMIN_SETTINGS = 'Admin Settings',
}

export enum ChannelSettingsContent {
  SETTINGS_HEADER_TEXT = 'Admin Settings',
  SETTINGS_DESCRIPTION_TEXT = 'Manage settings from this menu',
  EDIT_CHANNEL_HEADING = 'Edit Channel Details',
  EDIT_CHANNEL_TEXT = 'Edit your channel name and description',
  DELETE_CHANNEL_HEADING = 'Delete Channel',
  DELETE_CHANNEL_TEXT = 'Nuke this channel from existence. This action is irreversible'
}

export enum ChannelViewMembersMenuItems {
  REMOVE_MEMBER = 'Remove from Channel',
}

export enum ChannelMessageMenuItems {
  EDIT_MESSAGE = 'Edit',
  DELETE_MESSAGE = 'Delete',
}
