export interface Report {
    id: string
    status: string | null
    type: string
    title: string
    content: string
    media: string | null
    flagged: boolean
    reports: string[]
    parentContentId: string | null
    userId: string
    createdAt: string
    updatedAt: string
}

export type CommentsType = {
    reason?: string,
    description?: string,
    referenceId: string,
    referenceType: string
}

export interface ReportAction {
    userId: string,
    type: string,
    comments: CommentsType,
    duration: number
}

export interface ReportActivity {
    id?: string
    adminId?: string
    reportedContentId: string
    reportedUserId: string
    action?: string
    actionDuration?: string
    feedback?: string
}

export interface UnBlockOrUnSuspendUserPayload {
    resolution: string
}

export enum ReportedUserActionType {
    BLOCK = 'blocked',
    SUSPEND = 'suspend',
    DELETE = 'delete',
}

export enum ReportActionTypes {
    POST = 'post',
    COMMENT = 'comment',
    JOB = 'job'
}

export interface IReportPayload {
    contentId?: string
    jobId?: string
    type: ReportActionTypes
    reason: string
    description?: string
    reporteeId: string
    status: string
    partnerId?: string
}

export interface IAssignedTo {
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    role: string,
    contentId: string,
    status?: any
}