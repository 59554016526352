export * from './user.types'
export * from './role.types'
export * from './profile.types'
export * from './question.types'
export * from './event.types'
export * from './content.types'
export * from './ticket.types'
export * from './searchResults.types'
export * from './comment.types'
export * from './report.types'
export * from './preference.types'
export * from './partner.types'
export * from './partnerFollower.types'
export * from './connection.types'
export * from './subscription.types'
export * from './admin.types'
export * from './usageSummary.types'
export * from './job.types'
export * from './socket.types'
export * from './channels.types'
export * from './delete-user-reason.types'
export * from './session.types'
export * from './slide.types'
export * from './registration.types'
export * from './actionContextMenuItem.types'

export interface BusinessObject {
  id?: string
}

export interface BackendPaginatedResponse<T> {
  page?: string
  pageSize?: string
  dir?: string
  sort?: string
  totalCount?: string
  data?: T[]
}

export interface SearchParamDefaults {
  [key: string]: string | number
  page?: number
  pageSize?: number
  dir?: string
  sort?: string
}
