export const MIXPANEL_PROPERTIES = {
  USER_ID: 'User ID',
  ACCOUNT_TYPE: 'Account Type',
  PARTNER_ID: 'Partner ID',
  COMPANY_NAME: 'Company Name',
  SELECTED_REACTION: 'Selected Reaction',
  POST_ID: 'Post ID',
  POST_AUTHOR_ID: 'Post author ID',
  REACTION_TYPE: 'Reaction Type',
  EMAIL_ADDRESS: 'Email address',
  HASHTAGS_ADDED: 'Hashtags added',
  VIDEO_ADDED: 'Video added',
  IMAGE_ADDED: 'Image added',
  SELECTED_PROFILE: 'Selected Profile',
  JOB_ID: 'Job ID',
  JOB_COMPANY: 'Job Company',
  JOB_NAME: 'Job Name',
  CONNECTION_VIEWER_EMAIL : 'Connection viewer email',
  CONNECTION_VIEWER_ID : 'Connection viewer ID',
  FROM_POST: 'From post',
  FROM_CHAT: 'From chat',
  FROM_PINGS: 'From pings',
  FROM_MANAGED_CONNECTIONS: 'From managed connections',
  FROM_SUGGESTED_CONNECTIONS: 'From suggested connections',
  MEMBER_PROFILE_VIEWED: 'Member profile viewed',
  PAGE_URL: 'Page Url',
  CONTENT_TYPE: 'Content type',
  PROFILE_ID_TAGGED: 'Profile ID tagged',
  SENDEE_ROLE: 'Sendee role',
  SENDER_ROLE: 'Sender role',
  SENDEE_ID: 'Sendee ID',
  SENDEE_EMAIL: 'Sendee Email',
  MESSAGE_RECIPIENT_ID: 'Message recipient ID',
  MESSAGE_RECIPIENT_EMAIL: 'Message recipient email',
  RECIPIENT_ROLE: 'Recipient role',
  MESSAGE_SENT: 'Message sent from Partner to Applicant',
  INVITED_BY: 'Invited by',
  SEARCH_QUERY: 'Search query',
  SEARCH_RESULTS: 'Search results',
  SELECTED_SEARCH_COMPANY: 'Selected search company',
  SELECTED_SEARCH_DATE_POSTED: 'Selected search Date posted',
  SELECTED_SEARCH_JOB_COUNTRY: 'Selected search Job country',
  SELECTED_SEARCH_JOB_STATE: 'Selected search Job province/state',
  SELECTED_SEARCH_JOB_CITY: 'Selected search Job city',
  SELECTED_SEARCH_JOB_TYPE: 'Selected search job type',
  SELECTED_SEARCH_JOB_TITLE: 'Selected search job title',
  SELECTED_SEARCH_JOB_WORKPLACE: 'Selected search job workplace',
  SELECTED_SEARCH_JOB_EXPERTISE: 'Selected search job area of expertise',
  USER_GROUP_ID: 'User Group ID',
  EVENT_ID: 'Event ID',
  EVENT_TITLE: 'Event Title',
  EVENT_TYPE: 'Event Type',
  GROUP_ID: 'Group ID',
  GROUP_NAME: 'Group Name',
  REMOVED_ID: 'Removed ID',
  ASSIGNEE_ID: 'Asignee ID',
  LOGIN_TYPE: 'Login Type',
  CONNECTION_REQUESTEE: 'Connection requestee',
  CONNECTION_REQUESTOR: 'Connection requestor',
  CONNECTION_REQUESTOR_ID: 'Connection requestor ID',
  TIME_AFTER_COMMENT: 'Time after comment',
  TIME_AFTER_POST: 'Time after post',
  EVENT_NAME: 'Event name',
  EVENT_DATE: 'Event date',
  DATE_OF_EVENT: 'Date of event',
  CONSENT_SHARE_INFORMATION_SWITCH: 'Consent share and obtain information switch',
  CONSENT_TERMS_CONDITIONS_SWITCH: 'Consent terms and conditions switch',
  REFERRER_EMAIL: 'Referrer email',
  SELECTED_PASS_CATEGORY: 'Selected Pass Category',
  NEWS_TITLE: 'News title',
  EMAIL: 'Email',
  OPTION_CHOSEN: 'Option chosen',
  PARTNER_TYPE: 'Partner Type',
  PARTNER_ID_FOLLOWED: 'Partner ID followed',
  COMPANY_NAME_FOLLOWED: 'Company Name followed',
  SELECTED_SEARCH_AREA_OF_EXPERTISE: 'Selected search Area of expertise',
  SELECTED_SEARCH_INDUSTRY: 'Selected search Industry',
  PROFILE_SEARCH_QUERY: 'Profile search query',
  PROFILE_SEARCH_RESULTS: 'Profile search results',
  REASONS: 'Reasons ',
  PARTNER_VIEWED: 'Partner Viewed',
  SEARCH_CATEGORY: 'Search Category',
  FILTERS_USED: 'Filters used',
  FILTER_CATEGORY: 'Filter Category',
  COMPANY_NAME_OF_VIEWER: 'Company Name of Viewer',
  EMAIL_ADDRESS_OF_VIEWER: 'Email address of viewer',
  PARTNER_ID_VIEWED: 'Partner ID Viewed',
  COMPANY_NAME_VIEWED: 'Company Name Viewed',
  ID_OF_VIEWER: 'ID of viewer',
  CONNECTION_REQUESTOR_EMAIL: 'Connection requestor Email',
  SPONSOR_NAME: 'Sponsor name',
  SPEAKER_VIEW_DATE: 'Speaker view date',
  VIDEO_NAME: 'Video name',
  DATE_WATCHED: 'Date watched',
  VIDEO_ID: 'Video ID',
  SPEAKER_ID: 'Speaker ID',
  SPEAKER_Name: 'Speaker Name',
}