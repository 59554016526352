import { createGlobalStyle } from 'styled-components/macro'
import { ThemeType, themeDark } from '../hooks/theme'

export { themeDark as theme }

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  html, body, #root {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: none;
  }

  body {
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: ${props => props.theme.colors.bgPrimary};
    color: ${props => props.theme.colors.accent1};
    font-family: ${props => props.theme.fonts.primary};
    font-size: 16px;
    line-height: 1.4;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  h1,h2,h3,h4 {
    font-weight: normal;
    line-height: 1.27;
  }

  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 3.75rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2.125rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1.25rem;
  }

  p, ul, ol {
    line-height: 1.5;
    margin-bottom: 20px;
    font-size: 0.875rem;
  }

  ul {
    list-style: disc inside none;
  }

  strong {
    font-weight: bold;
  }

  a {
    text-decoration: none; /* no underline */
    cursor: pointer;
    color: inherit; // no default colors
  }
`
