import { SetStateAction } from 'react'

export interface JobsApplication {
  id?: string
  avatar: string
  name: string
  title: string
  description: string
  bio?: string
  email?: string
  role?: string
  previewImage?: string
}

export interface IApplicant {
  id: string
  userId: string
  jobId: string
  email: string
  phone: string
  resume: string
  createdAt: string
  appliedDate: Date
  isNotified: boolean
  hired: boolean
  coverLetter?: string
}
export interface IApplicants {
  page: string
  pageSize: string
  dir: JobSortEnum
  sort: string
  totalCount: number
  data: IApplicants[]
}
export interface IApplicantParams {
  jobId: string
  applicantId: string
}
export type JobSkillSetType = { show: boolean; skills: string[] }
export type JobListType = Record<string, string>[]
export type salaryRangeType = {
  frequency: string
  max: string
  min: string
  show: boolean
  currency: string
}

export enum JobDirEnum {
  DESCENDING = 'desc',
  ASCENDING = 'asc'
}

export enum JobSortEnum {
  CREATED_AT = 'created_at'
}
export type JobSalaryRangeType = {
  max: string
  min: string
  show: boolean
  currency: string
  frequency: string
}
export type JobType = {
  id: string
  image_url: string
  title: string
  mode: string
  company: string
  link: string
  jobDescription: string
  jobDescriptionArea: string
  location: string
  responsibilities: string
  qualifications: string
  jobType: string
  salary: string
  minPay: string
  maxPay: string
  currency: string
  frequency: string
  workplace: string
  skillSet: any
  showSkillSet: boolean
  showSalaryRange: boolean
  salaryRange: salaryRangeType
  jobUrl?: string
  areaOfExpertise: string
  industry?: string
}

export type JobProps = {
  id: string
  userId: string
  partnerId: string
  title: string
  jobDescription: string
  jobType: string
  workplace: string
  location: string
  attachments: unknown | null
  skillSet: JobSkillSetType
  salaryRange: JobSalaryRangeType
  updatedBy: unknown | null
  status: string
  expiryDate: string
  createdAt: string
  companyName: string
  companyLogo: string | null
  responsibilites?: string[] | null
  qualifications?: string
  jobUrl?: string
  isApplyDisabled?: boolean
  areaOfExpertise: string
  industry?: string
  saved?: boolean
}

export type JobOrderCriteria = {
  areaOfExpertise?: string
  industry?: string
}

export type JobOrderPriority = {
  areaOfExpertise?: string
  industry?: string
}

export interface JobFeedResponse {
  page: string
  pageSize: string
  dir: JobSortEnum
  sort: string
  totalCount: string
  data: JobProps[]
}
export interface JobResponse {}
export interface JobsDropDownMenu {
  selected: string
  setSelected: SetStateAction<string>
}

export interface IJobFilter {
  [IJobProps.DATE_POSTED]?: string
  [IJobProps.JOB_TYPE]?: string
  [IJobProps.WORKPLACE]?: string
  [IJobProps.COUNTRY]?: string
  [IJobProps.PROVINCES]?: string
  [IJobProps.CITY]?: string
  [IJobProps.COMPANY_NAME]?: string
  [IJobProps.JOB_TITLE]?: string
  [IJobProps.INDUSTRY]?: string
  [IJobProps.AREA_OF_EXPERTISE]?: string
  [IJobProps.TITLE_AND_COMPANY]?: string
}

export enum IJobProps {
  DATE_POSTED = 'datePosted',
  JOB_TYPE = 'jobType',
  WORKPLACE = 'workplace',
  COUNTRY = 'country',
  PROVINCES = 'province',
  CITY = 'city',
  COMPANY_NAME = 'companyName',
  JOB_TITLE = 'jobTitle',
  INDUSTRY = 'industry',
  AREA_OF_EXPERTISE = 'areaOfExpertise',
  TITLE_AND_COMPANY = 'titleAndCompany'
}

export enum CreateJobActionType {
  NEW = 'new',
  REPOST = 'repost',
  EDIT = 'edit',
  RENEW = 'renew'
}

export enum JobStatus {
  CLOSED = 'closed',
  ACTIVE = 'active',
  EXPIRED = 'expired'
}
export enum JobSaveStatus {
  SAVED = 'Saved',
  UNSAVED = 'Save'
}
