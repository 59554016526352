import { Box, Avatar, Typography } from '@material-ui/core'
import { Receiver } from 'common/types/message.types'
import { useHistory } from 'react-router-dom'
import { ConnectedStatusIcon } from 'components/page/direct-messaging/components/ConnectedStatusIcon'
import { checkSocketUserIsConnected } from 'hooks/use-online-status/useOnlineStatus'
import styled from 'styled-components'
import { useAuth } from 'pages/auth/hooks/useAuth'
import { useAnalytics } from 'hooks/use-analytics/useAnalytics'
import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'

const StyledSenderName = styled(Box)`
  cursor: pointer;
`
export interface IDirectMessageHeaderProps {
  receiver: Receiver
  theme: any
}

export const DirectMessageHeader = ({ receiver, theme }: IDirectMessageHeaderProps) => {
  const adminCompanyNamePrepend = 'Admin - '
  const cutLength = 25
  const history = useHistory()
  const { backendUser, backendPartner } = useAuth()
  const { logEventAnalytics } = useAnalytics()

  const viewProfile = () => {
    const partnerLog = {}
    const userLog = {}
    if (backendUser?.partnerId) {
      partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
      partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
      partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
    } else {
      userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = backendUser?.role
    }

    logEventAnalytics(
      backendUser?.partnerId ? 'Partner' : backendUser?.role,
      'Profile viewed',
      'mixpanel',
      'Profile viewed',
      {
        'Member profile viewed': receiver?.id,
        [MIXPANEL_PROPERTIES?.USER_ID]: backendUser?.id,
        [MIXPANEL_PROPERTIES?.FROM_POST]: false,
        [MIXPANEL_PROPERTIES?.FROM_CHAT]: true,
        [MIXPANEL_PROPERTIES?.FROM_MANAGED_CONNECTIONS]: false,
        [MIXPANEL_PROPERTIES?.FROM_PINGS]: false,
        [MIXPANEL_PROPERTIES?.FROM_SUGGESTED_CONNECTIONS]: false,
        ...partnerLog,
        ...userLog
      },
      backendUser?.id,
      {
        $name: `${backendUser?.firstName} ${backendUser?.lastName}`,
        $email: backendUser?.username
      }
    )

    history.push(`/profile/${receiver?.id}`)
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        paddingRight="3px"
        position="relative"
        style={{
          cursor: 'pointer'
        }}
      >
        <Avatar src={receiver?.image} onClick={viewProfile} style={{ border: `1px solid ${theme.colors.accent1}` }} />
        <ConnectedStatusIcon connected={checkSocketUserIsConnected(receiver?.id)} />
      </Box>
      <Box paddingLeft="1rem" marginBottom="0.5em" marginTop="0.5em" alignSelf="center">
        <Typography variant="subtitle2">
          <StyledSenderName color={theme.colors.accent1} fontWeight="bold" onClick={viewProfile}>
            {receiver?.name?.slice(0, 20) + (receiver?.name?.length > 20 ? '...' : '')}
          </StyledSenderName>
        </Typography>
        {!receiver?.isConnection && receiver?.hasMessagePermission && receiver?.companyName && (
          <Typography>
            <Box color={theme.colors.accent} fontWeight="bold" fontSize="0.7rem">
              {adminCompanyNamePrepend}
              <Box
                style={{
                  display: 'inline',
                  color: theme.colors.primary,
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  history.push('/company/' + receiver?.companyId)
                }}
              >
                {receiver?.companyName &&
                  `${receiver?.companyName.slice(0, cutLength)}${
                    receiver?.companyName.length > cutLength ? '...' : ''
                  }`}
              </Box>
            </Box>
          </Typography>
        )}
      </Box>
    </>
  )
}
