import axios from 'axios'
import { getApiKey, getAuthorizationToken, getUserFromToken, storeToken } from 'hooks/use-security/useSecurity'
import { notify } from '../use-notification/useNotification'
import { clearAll } from 'hooks/use-local-storage/useLocalStorage'
import { sleep } from 'common/utils/sleep'

const { REACT_APP_API_URL } = process.env

const authErrors = [
  'Invalid refresh token',
  'Missing refresh token',
  'Session not found',
  'JWT verification failed: jwt expired',
  'Invalid access token'
]

const refreshUrl = (userId: string) => `sessions/user/${userId}/refresh`

const refreshToken = async (userId: string) => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'x-api-key': getApiKey()
    }
    headers.Authorization = 'Bearer token'
    const client = axios.create({
      baseURL: REACT_APP_API_URL,
      timeout: 30000,
      headers: headers,
      withCredentials: true
    })
    const response = await client.put(`${REACT_APP_API_URL}/${refreshUrl(userId)}`)
    const data = await response.data
    return data
}

const handleAuthorizationToken = async () => {
  let token = getAuthorizationToken()
  if (!token) {
    notify('error', "Oops! Your token cannot be found. Let's start again.")
    clearAll()
    setTimeout(() => {
      window.location.assign(`${process.env.REACT_APP_REDIRECT_URI}`)
    }, 3000)
  }
  if (token == 'expired') {
    const user = getUserFromToken()
    if (!user) {
      notify('error', 'You must be logged in to perform this action')
      return
    }

    let retryAttempts = 5
    while (retryAttempts > 0) {
      try {
        const session = await refreshToken(user.id)
        if (session) {
          token = session.accessToken
          storeToken(token)
          return token
        } else {
          notify('error', 'Session error occurred.  Please try again.')
          return
        }
      } catch (e) {
        if (e.response?.status === 503) {
          await sleep(250)
          const token = getAuthorizationToken()
          if (!!token && token !== 'expired') return token
          retryAttempts -= 1
        } else {
          const data = {
            source: 'useHttp.post',
            url: `${REACT_APP_API_URL}/${refreshUrl(user.id)}`
          }
          handleHttpError(e, data, { dieSilent: false })
          retryAttempts = 0
          return Promise.reject(e)
        }
      }
    }
  }
  return token
}

const getClient = async (options?: any) => {
  const token = options.useApiKey ? 'token' : await handleAuthorizationToken()
  const headers: HeadersInit = options?.notJSON
    ? {}
    : {
      'Content-Type': 'application/json'
    }

  headers.Authorization = `Bearer ${token}`
  headers['x-api-key'] = options.useApiKey ? getApiKey() : ''
  const client = axios.create({
    baseURL: REACT_APP_API_URL,
    timeout: options?.timeout || 30000,
    headers: headers,
    withCredentials: true
  })
  return client
}

const setDefaults = (options: any) => {
  if (!options) {
    options = {}
  }

  if (!options.hasOwnProperty('errorMessage')) {
    // default to generic api error
    options.errorMessage = 'A server error has occurred.  Please try again.'
  }

  return options
}

const handleHttpError = (errorObject, data, options: any) => {
  if (
    errorObject.response?.status === 400 ||
    errorObject.response?.status === 401 ||
    errorObject.response?.status === 404
  ) {
    //toDo
    //navigate('/login')
    let errorMessage = errorObject.response?.data?.httpMessage
    try {
      const message = JSON.parse(errorMessage)
      if (message.message) errorMessage = message.message
    } catch (e) { }

    if (errorMessage?.includes('Message=Presentation not currently available.')) {
      return notify('error', 'Event is not live yet')
    }

      if (authErrors.includes(errorMessage) || errorMessage?.includes('JWT verification failed:')) {
        notify('error', "Oops! It seems like your session is invalid. Let's start again.")
        clearAll()
        setTimeout(() => {
          window.location.assign(`${process.env.REACT_APP_REDIRECT_URI}`)
        }, 3000)
        return 
      }

    !options?.dieSilent && notify('error', errorMessage)
    return options?.dieSilent ? '' : errorObject?.response?.data?.httpMessage
  }
  if (options?.dieSilent) return
  switch (errorObject?.response?.data?.httpMessage) {
    case 'PayloadTooLargeError':
      notify('error', 'Your Post was too large. Maximum acceptable size is 25mb')
      break
    default:
      notify('error', errorObject?.response?.data?.httpMessage || options.errorMessage)
      break
  }
  return options?.dieSilent ? '' : errorObject?.response?.data?.httpMessage || options.errorMessage
}

export const get = async <T = any>(url, _options?: any): Promise<T> => {
  const options = setDefaults(_options)
  try {
    const client = await getClient(options)
    const response = await client.get(url)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.get',
      url: `${REACT_APP_API_URL}/${url}`
    }
      handleHttpError(e, data, options)

    return Promise.reject(e)
  }
}

export const put = async <T = any>(url, requestData, _options?: any): Promise<T> => {
  const options = setDefaults(_options)
  try {
    const client = await getClient(options)
    const response = await client.put(url, requestData)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.put',
      url: `${REACT_APP_API_URL}/${url}`,
      requestData
    }
    if (options.handleError) {
      return { error: true, message: e?.response?.data?.httpMessage ?? options.errorMessage } as any
    } else {
      const backendError = handleHttpError(e, data, options)
      return Promise.reject(backendError)
    }
  }
}

export const post = async <T = any>(url, requestData, _options?: any): Promise<T> => {
  const options = setDefaults(_options)

  try {
    const client = await getClient(options)
    const response = await client.post(`${REACT_APP_API_URL}/${url}`, requestData)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.post',
      url: `${REACT_APP_API_URL}/${url}`,
      requestData
    }
    handleHttpError(e, data, options)

    return Promise.reject(e)
  }
}

export const destroy = async <T = any>(url, _options?: any, requestData?: any): Promise<T> => {
  const options = setDefaults(_options)

  try {
    const client = await getClient(options)
    const response = await client.delete(`${REACT_APP_API_URL}/${url}`)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.delete',
      url: `${REACT_APP_API_URL}/${url}`
    }
    handleHttpError(e, data, options)

    return Promise.reject(e)
  }
}

export const useHttp = () => {
  return {
    get,
    put,
    post,
    destroy
  } as const
}
