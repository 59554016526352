import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import { createContext, useState, useEffect, FunctionComponent, Context, useContext, useCallback } from 'react'
import { Receiver, Message } from 'common/types/message.types'
import { useSocket } from 'hooks/use-socket/useSocket'
import { SocketUser, SocketEvent } from 'common/types'
import AuthContext from 'contexts/auth/AuthProvider'
import { useLocation } from 'react-router-dom'

interface IMessagingContext {
  isOpen: boolean
  receiver?: Receiver
  setReceiver: (receiver: Receiver) => void
  setOpen: (open: boolean) => void
  onlineUsers: SocketUser[]
  newReceivedMessage: Message
  newSentMessage: Message
  clearSentMessage: () => void
  archivedConversationUser: string
  clearArchivedConversationUser: () => void
  setReceiverParam: (receiverId: string) => void
}

export const MessagingContext: Context<IMessagingContext> = createContext<IMessagingContext>({
  isOpen: false,
  setReceiver: (receiver) => {},
  setOpen: (open) => {},
  onlineUsers: null,
  newReceivedMessage: null,
  newSentMessage: null,
  clearSentMessage: () => null,
  archivedConversationUser: null,
  clearArchivedConversationUser: () => null,
  setReceiverParam: (receiverId) => {}
})

export const MessagingProvider: FunctionComponent= ({ children }) => {
  const [isOpen, _setOpen] = useState(false)
  const [receiver, _setReceiver] = useState<Receiver>()
  const {getItem, setItem} = useLocalStorage()
  const { get, socket, set } = useSocket()
  const [onlineUsers, setOnlineUsers] = useState<SocketUser[]>()
  const [newReceivedMessage, setNewReceivedMessage] = useState<Message>()
  const [newSentMessage, setNewSentMessage] = useState<Message>()
  const { backendUser } = useContext(AuthContext)
  const [archivedConversationUser, setArchivedConversationUser] = useState<string>()
  const [receiverIdParam, setReceiverIdParam] = useState<string>()
  const { pathname } = useLocation()

  useEffect(() => {
    const open = getItem('isOpen') === 'true'
    const receiver = JSON.parse(getItem('receiver'))
    
    if(open && receiver){
      _setOpen(open)
      _setReceiver(receiver)
    }
  }, [])


  useEffect(() => {
      
      get(SocketEvent.UsersOnline, (users) => {
          setOnlineUsers(prev => users)
      })
    
      get(SocketEvent.ChatMessageCreated, (message: Message) => {
        if(backendUser && message.receiverId == backendUser.id) {
          if(isOpen && receiver?.id === message.senderId || (pathname == `/messages/${receiverIdParam}` && receiverIdParam === message.senderId)) {
            message.status = "read"
            set(SocketEvent.ChatMessageRead, {id: message.id, payload: {"status": "read"}})
          }
          setNewReceivedMessage(message)
        } else if (backendUser && message.senderId == backendUser.id) {
          setNewSentMessage(message)
        }
      })
    
      get(SocketEvent.UserDisconnected, (data: {user: string, users: any[]}) => {
        setOnlineUsers(prev => data.users)
    })

    get(SocketEvent.ChatArchived, (userId: string) => {
      setArchivedConversationUser(userId)
    })
    
    return () => {
      socket.off(SocketEvent.UsersOnline)
      socket.off(SocketEvent.ChatMessageCreated)
      socket.off(SocketEvent.UserDisconnected)
     }
  }, [onlineUsers, newReceivedMessage, isOpen, receiver, pathname])

  const setOpen = (open: boolean) => {
    _setOpen(open)
    setItem('isOpen', JSON.stringify(open))
  }

  const setReceiver = (receiver: Receiver) => {
    _setReceiver(receiver)
    setItem('receiver', JSON.stringify(receiver))
  }

  const setReceiverParam = (receiverId: string) => {
    setReceiverIdParam(receiverId)
  }

  const clearSentMessage = useCallback(() => setNewSentMessage(null), [])
  const clearArchivedConversationUser = useCallback(() => setArchivedConversationUser(null), [])
  return (
    <MessagingContext.Provider 
      value={{
        isOpen,
        setOpen,
        receiver,
        setReceiver,
        onlineUsers,
        newReceivedMessage,
        newSentMessage,
        clearSentMessage,
        archivedConversationUser,
        clearArchivedConversationUser,
        setReceiverParam
      }}
    >
      {children}
    </MessagingContext.Provider>
  )
}

export default MessagingContext
