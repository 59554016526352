import { MouseEvent, useState } from 'react'

export function useCurrentTarget() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCustomEventClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleReset = (e?) => {
    e?.stopPropagation()
    setAnchorEl(null)
  }

  return { handleClick, handleReset, anchorEl, handleCustomEventClick, setAnchorEl } as const
}
