import { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'
import {
  DrawerCloseArrow,
  DrawerOpenArrow,
  StyledChatBar,
  ChatsContainer,
  ChatsDrawerWrapper,
  NewMessageIcon,
  StyledTypographyBox
} from './styled'
import { useMessages } from 'hooks/use-messages/useMessages'
//@ts-ignore
import { useTheme } from 'styled-components'
import { ChatsDrawerListItem } from './ChatsDrawerListItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from 'components/core/loader'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
import './styles.css'
import { useAuth } from 'pages/auth/hooks/useAuth'
import { useAnalytics } from 'hooks/use-analytics/useAnalytics'
import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'

export const ChatsDrawer: FunctionComponent = () => {
  const theme = useTheme()
  const { isDevice } = useScreenSizes()
  const { backendPartner } = useAuth()
  const {
    user,
    openDrawer,
    setDrawer,
    messages,
    totalMessages,
    page: messagePage,
    setPage: setMessagePage,
    showNotification,
    setShowNotification,
    setOpen,
    setReceiver,
    getMessages
  } = useMessages()
  const { setItem } = useLocalStorage()
  const { pathname } = useLocation()
  const { logEventAnalytics } = useAnalytics()
  const onPartnerOnboarding = pathname.includes('/settings/partner') || pathname.includes( '/signup')
  const onSummit = pathname.includes('/summit') || pathname.includes('bfutr')

  const handleDrawer = async (action: 'open' | 'close') => {
    if (messages.length > 0) {
      const store = {
        createdAt: messages[0]?.createdAt,
        senderId: messages[0]?.senderId
      }
      setItem('messages', JSON.stringify(store))
    }
    if (action == 'open') {
      await getMessages()
      setDrawer(true)
    } else if (action == 'close') {
      setDrawer(false)
    }
    setShowNotification(false)
  }

  return (
    <ChatsDrawerWrapper
      show={user && user.enabled && user.isOnBoarded && !onPartnerOnboarding && !isDevice && !onSummit}
    >
      <StyledChatBar
        openDrawer={openDrawer}
        onClick={() => {
          openDrawer ? handleDrawer('close') : handleDrawer('open')
          const partnerLog = {}
          const userLog = {}

          userLog[MIXPANEL_PROPERTIES?.USER_ID] = user?.id
          userLog[MIXPANEL_PROPERTIES?.EMAIL_ADDRESS] = user?.username

          if (user?.partnerId) {
            partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
            partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
            partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
          } else {
            userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = user?.role
          }
          logEventAnalytics(
            user?.partnerId ? 'Partner' : 'Member',
            'Chat clicks',
            'mixpanel',
            'Chat clicks',
            { ...partnerLog, ...userLog },
            user?.id
          )
        }}
      >
        <Box display="flex" onClick={() => {}}>
          <Typography variant="body1">
            <StyledTypographyBox fontWeight="500">Chat</StyledTypographyBox>
          </Typography>
          {showNotification && <NewMessageIcon />}
        </Box>
        {openDrawer ? (
          <DrawerCloseArrow onClick={() => handleDrawer('close')} />
        ) : (
          <DrawerOpenArrow onClick={() => handleDrawer('open')} />
        )}
      </StyledChatBar>
      <ChatsContainer openDrawer={openDrawer} id="scrollableDiv">
        <InfiniteScroll
          loader={<Loader />}
          hasMore={totalMessages > messages?.length}
          endMessage={
            <p style={{ textAlign: 'center', color: theme.colors.accent1 }}>
              {messages?.length === 0 ? <b>No Chats</b> : <b>No More Chats</b>}
            </p>
          }
          next={() => {
            setMessagePage(messagePage + 1)
          }}
          dataLength={messages?.length}
          scrollableTarget="scrollableDiv"
        >
          {messages?.map((message, idx) => {
            return (
              <ChatsDrawerListItem
                key={message?.id + idx}
                message={message}
                setOpenChat={setOpen}
                setReceiver={setReceiver}
                openDrawer={openDrawer}
              />
            )
          })}
        </InfiniteScroll>
      </ChatsContainer>
    </ChatsDrawerWrapper>
  )
}
