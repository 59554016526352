import styled from 'styled-components/macro'
import { Box } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

export const DrawerCloseArrow = styled(KeyboardArrowDown)`
  && {
    fill: ${props => props.theme.colors.accent1};
    cursor: pointer;
  }
`
export const DrawerOpenArrow = styled(KeyboardArrowUp)`
  && {
    fill: ${props => props.theme.colors.accent1};
    cursor: pointer;
  }
`

export const ChatsDrawerWrapper = styled(Box)<{ show: boolean }>`
  && {
    display: ${props => (props.show ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: auto;
    bottom: 4.375em;
    right: 0;
    border: 1px solid ${props => props.theme.colors.primary};
    box-shadow: 0px 0px 17px 9px rgba(0, 0, 0, 0.2);
    border-radius: 10px 10px 0px 0px;
    background-color: ${props => props.theme.colors.supplementary};
    width: 18em;
    z-index: 1000;
  }
`

export const StyledChatBar = styled(Box)<{ openDrawer: boolean }>`
  && {
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.colors.accent1};
    height: 3.4em;
    padding: 0.8em 0.75em;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`
export const ChatsContainer = styled(Box)<{ openDrawer: boolean }>`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  display: ${props => (props.openDrawer ? 'flex' : 'none')};
  height: ${props => (props.openDrawer ? '60vh' : 0)};
  transition: all 0.2s ease-in-out;
  overflow-y: scroll;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.supplementary1};
  /* @media (min-height: ${props => props.theme.breakpoints.large}) {
    height: 65%;
  } */
`

export const NewMessageIcon = styled(Box)`
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  background: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.accent1};
  border-radius: 50%;
`

export const StyledTypographyBox = styled(Box)`
  && {
    font-family: 'Poppins';
    overflow-x: hidden;
  }
`
