import { BusinessObject, ReportedUserActionType } from '.'
import { IMentionedPayload } from './mentions.types'

export interface ContentFeed {
  page: number
  pageSize: number
  dir: string
  sort: string
  totalCount: number
  data: Content[]
}

export interface BackendContent extends BusinessObject {
  id?: string
  contentId?: string
  title?: string
  content?: any[] | string
  media?: any[] | string
  type: string
  createdAt?: string
  updatedAt?: string
  partnerId?: string
  mentions?: IMentionedPayload[]
  tags?: string[]
}

export interface ContentActivity extends BusinessObject {
  id?: string
  contentId: string
  type: string
  typeValue: string
  userId: string
  parentContentId: string
  partnerId: string
}

export interface Content extends BusinessObject {
  status?: string
  key?: string
  type: string
  contentTitle: string
  title?: string
  contentId?: string
  content?: any[] | string
  media?: any[] | string
  flagged?: boolean
  reportedBy?: string[]
  parentContentId?: string
  userId?: string
  firstName: string
  lastName: string
  image: string
  logo?: string
  connectionTitle: string
  reactionCount?: string | number
  commentCount?: number
  createdAt?: string
  updatedAt?: string
  mediaType?: 'image' | 'video' | null
  siblingContent?: SiblingContent
  partnerId?: string
  companyName?: string
}

export enum ReportedContentAction {
  VIEW_REPORT = 'View Report',
  CLOSE_REPORT = 'Close Report',
  ASSIGN_TO_MYSELF = 'Assign to Myself',
  DELETE_POST = 'Delete Content',
  SUSPEND_USER = 'Suspend User',
  BLOCK_USER = 'Block User',
  CLOSE_REPORTED_CONTENT = 'Close Report',
  REOPEN_CLOSED_REPORT = 'Reopen Report'
}

export enum ReportedContentStatus {
  OPEN_REPORT = 'opened',
  CLOSED_REPORT = 'closed',
  RESOLVED_REPORT = 'resolved'
}

export interface ReportedUser extends BusinessObject {
  id?: string
  adminId?: string
  reinstated?: boolean
  reinstatedDate?: string
  adminReinstatedDate?: string
  createdAt?: string
  blockedUserName?: string
  userId: string
  type?: ReportedUserType
  comments?: ReportedUserComments
  duration?: number
  role?: string
}

export interface ReportedUserComments extends BusinessObject {
  reason?: string
  description?: string
  referenceId?: string
  referenceType?: string
}

export interface ReportedContent extends BusinessObject {
  data?: any[]
  id?: string
  jobId?: string
  contentId?: string
  title?: string
  type?: string
  reporterId?: string
  reporteeId?: string
  partnerId?: string
  reason?: string
  resolution?: string[]
  status?: ReportedContentStatus 
  description?: string
  totalCount?: string
  reporter?: string
  contentOwner?: string
  contentOwnerId?: string
  contentReporterFirstName?: string
  contentReporterLastName?: string
  contentOwnerFirstName?: string
  contentOwnerLastName?: string
  createdAt?: string
  contentTitle?: string
  assignedTo?: string
  contentRef?: string
}

export interface SharedPostContent extends Content {
  title: string
  siblingContent: SiblingContent
}

export interface SiblingContent {
  content: Array<Comment | ContentActivity[]>
  originalPosterFirstName: string
  originalPosterHeadline: string
  originalPosterImage: string
  originalPosterLastName: string
  originalPosterCompanyName?: string
}

export interface Reaction {
  reaction: string
  count: number
}

export interface UserReaction {
  id: string
  type: string
  typeValue: string
  parentContentId: string
  firstName: string
  lastName: string
  company: string
  userTitle: string
  userId: string
  image: string
  partnerId?: string
  companyName?: string
  logo?: string
}

export type Insight = {
  connectionCount: string
  eventCount: string
  followerCount: string
}

export interface UserReactionParams {
  page: number
  pageSize: number
  dir: string
  sort: string
  totalCount: number
  data: UserReaction[]
}

export interface ContentLog {
  id: string
  userId: string
  contentId: string
  partnerId: string
  action: string
  name: string
  createdAt: string
  feedback: string
}

export const CREATE_POST_TITLE_PLACEHOLDER = 'Title'
export const CREATE_POST_DESCRIPTION_PLACEHOLDER = 'Description'
export const CREATE_COMMENT_PLACEHOLDER = 'Comment'
export const ADD_CHANNEL_USER_PLACEHOLDER = 'Add people using their names e.g Sammie'
export const CREATE_REPOST_PLACEHOLDER = 'Write something...'

export enum ReportedUserHeadLine {
  CONTENT_OWNER = 'Content Owner',
  CATEGORY = 'Category',
  REASON = 'Reason',
  TYPE = 'Type',
  STATUS = 'Status',
  ACTION = 'Action'
}

export enum ReportedUserType {
  SUSPENDED = 'suspended',
  BLOCKED = 'blocked',
  UNSUSPEND = 'Unsuspend',
  UNBLOCK = 'Unblock',
  CLOSE = 'Close',
  BLOCK = 'Block',
  SUSPEND = 'Suspend'
}

export enum CursorElement {
  TITLE = 'title',
  DESCRIPTION = 'description'
}

export enum PostTypes {
  ARTICLE = 'article',
  SHARE = 'share',
  ACTIVITY = 'activity'
}

export interface ReportActivityLog extends BusinessObject {
  admin?: string,
  action?: string,
  adminFeedback?: string,
  createdAt?: string
}

export interface ReportLog {
  reportId: string 
  reporterFirstName: string
  reporterLastName: string
  reportReason: string 
  createdAt: string
}

export interface PinnedPost {
  isActive: boolean
  description: string
  buttonText: string
  url: string
}



