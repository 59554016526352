import { useMemo } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { materialUiTheme as materialUiThemeJson } from './materialUITheme'
import { frFR } from '@material-ui/core/locale'
import { whiteLabelTheme } from './whiteLabelTheme'

export const useMaterialUiTheme = (mode: string, selectedLanguage: string, shouldUseWhiteLabel: boolean) => {
    const { result } = materialUiThemeJson(mode)

    const theme = useMemo(() => {

        if (shouldUseWhiteLabel) {
            // we intercept our theme and we modify it to make it 'White Label'
            let themeInUse: { palette?: any } = {}
            themeInUse.palette = {
                primary: {
                    light: whiteLabelTheme.primaryLight,
                    main: whiteLabelTheme.primaryMain
                }
            }
        }

        if (selectedLanguage === 'fr') {
            createMuiTheme(result(), frFR)
        }
        return createMuiTheme(result())
    }, [mode])
    return {
        theme
    }
}
