import { useToasts, ToastConsumer, ToastProvider, DefaultToast } from 'react-toast-notifications'
import { NotifyBody } from './NotifyBody'
import toast from 'react-hot-toast'
import { WarningRounded } from '@material-ui/icons'
import { themeDark } from 'hooks/theme'

type NotificationType = 'success' | 'error' | 'warning' | 'info'

export enum NotificationEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

/**
 * Creates a notification toast
 * 
 * @param type Type of notification
 * @param message What should show up in toast
 */
export const notify = (type: NotificationType, message: string) => {
  setTimeout(() => {
    switch (type) {
      case NotificationEnum.SUCCESS:
        toast.success(message)
        break
      case NotificationEnum.INFO:
        toast(message)
        break
      case NotificationEnum.WARNING:
        toast(message,
        {
          icon: <WarningRounded htmlColor="#fbc02d" />,
        })
        break
      case NotificationEnum.ERROR:
        toast.error(message)
        break
    }
  }, 520)
}

export const useNotification = () => {
  return {
    notify
  } as const
}
