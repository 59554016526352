import { Box, Typography } from '@material-ui/core'
import { CustomIconContextMenu } from 'components/core/actions-menu-noborder/CustomIconContextMenu'
import { ConfirmModal } from 'components/core/modal'
import { useDirectMessaging } from 'hooks/use-direct-messaging/useDirectMessaging'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
import { FunctionComponent, useState } from 'react'
import { DirectMessagingList } from './DirectMessagingList'
//@ts-ignore
import { useTheme } from 'styled-components'

import { DirectMessageHeader } from './DirectMessageHeader'
import {
  StyledContextIcon,
  StyledContextIconButton,
  StyledDeleteIcon,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledHeaderIcon,
  StyledLargeDeleteIcon,
  StyledMessageContainer,
  StyledMessageContainerInner
} from './style'
import { SubmitMessage } from './SubmitMessage'
import { useAuth } from 'pages/auth/hooks/useAuth'

const { IconButton, CloseIcon, ThemeProvider } = require('@livechat/ui-kit')

export const DirectMessagingCard: FunctionComponent = () => {
  const theme = useTheme()
  const { isDevice } = useScreenSizes()
  const [showConversationDelete, setShowConversationDelete] = useState(false)
  const {
    deleteConversation,
    receiver,
    setOpen,
    sender,
    isOpen,
    handleSubmit,
    handleChange,
    typedMessage,
    activeChat,
    editedMessage,
    setEditedMessage,
    editMessageId,
    setEditMessageId,
    formatDate,
    getTimeCreated,
    showEdit,
    setShowEdit,
    disablePopup,
    setDisablePopup,
    handleEdit,
    handleDelete,
    deleteMessageId,
    setDeleteMessageId,
    isAuthenticated,
    editMessage,
    receiverMaxDMCount
  } = useDirectMessaging()

  const { backendUserFunctions, backendUsageSummary } = useAuth()
  const hasMessagePermission: boolean = backendUserFunctions?.some(permission => permission.name === 'DM')
  const maxDMCount = backendUsageSummary?.currentDMCount >= backendUsageSummary?.totalDM

  if (!isOpen || !receiver || !sender || !isAuthenticated) {
    setOpen(false)
    return null
  }

  return (
    <ThemeProvider theme={{}}>
      <StyledMessageContainer isDevice={isDevice}>
        <StyledMessageContainerInner>
          <StyledHeaderContainer
            style={{
              paddingLeft: '1rem',
              paddingRight: '1rem',
              borderBottom: `1px solid ${theme.colors.supplementary1}`
            }}
          >
            <DirectMessageHeader receiver={receiver} theme={theme} />
            <StyledHeaderIcon>
              <CustomIconContextMenu title="" icon={<StyledContextIcon />}>
                <StyledContextIconButton
                  size="small"
                  onClick={() => setShowConversationDelete(true)}
                  style={{ padding: '0px !important' }}
                >
                  <Box m="-0.5em 0" display="flex" flexDirection="row" alignItems="center">
                    <StyledDeleteIcon />
                    <Box>
                      <Typography component="span" variant="subtitle2">
                        <Box color={theme.colors.accent1} m="0 0.5em">
                          Delete Conversation
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </StyledContextIconButton>
              </CustomIconContextMenu>
              <IconButton>
                <CloseIcon onClick={() => setOpen(false)} />
              </IconButton>
            </StyledHeaderIcon>
          </StyledHeaderContainer>
          <DirectMessagingList
            activeChat={activeChat}
            editedMessage={editedMessage}
            setEditedMessage={setEditedMessage}
            editMessageId={editMessageId}
            setEditMessageId={setEditMessageId}
            sender={sender}
            formatDate={formatDate}
            getTimeCreated={getTimeCreated}
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            disablePopup={disablePopup}
            setDisablePopup={setDisablePopup}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            deleteMessageId={deleteMessageId}
            setDeleteMessageId={setDeleteMessageId}
          />

          <StyledFooterContainer isDevice={isDevice}>
            <SubmitMessage
              setShowEdit={setShowEdit}
              handleChange={(e: any) => (showEdit ? setEditedMessage(e) : handleChange(e))}
              setEditedMessage={setEditedMessage}
              editMessageId={editMessageId}
              editMessage={editMessage}
              isDevice={isDevice}
              showEdit={showEdit}
              // typedMessage={showEdit ? decodeURIComponent(editedMessage) : typedMessage}
              typedMessage={
                showEdit ? decodeURIComponent(editedMessage.replaceAll('\n', '<div><br></div>')) : typedMessage
              }
              sender={sender}
              receiver={receiver}
              handleSubmit={showEdit ? text => handleEdit(editMessageId, text) : handleSubmit}
              isConnection={receiver?.isConnection ?? true}
              hasMessagePermission={(receiver?.hasMessagePermission || hasMessagePermission) ?? true}
              theme={theme}
              maxDMCount={maxDMCount}
              receiverMaxDMCount={receiverMaxDMCount}
            />
          </StyledFooterContainer>
        </StyledMessageContainerInner>
        <ConfirmModal
          title=""
          message="Are you sure you want to delete your entire conversation?"
          onClose={() => setShowConversationDelete(false)}
          open={showConversationDelete}
          onConfirm={() => {
            deleteConversation(sender?.id, receiver.id)
            setOpen(false)
          }}
          showCancel
          icon={<StyledLargeDeleteIcon />}
        />
      </StyledMessageContainer>
    </ThemeProvider>
  )
}
