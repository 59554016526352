import { makeAutoObservable } from 'mobx'

class SignupAlertState {
  showAlertState = false
  userEmail = ''
  showExpiredError = false
  isTimerExpired = false
  isOTPSent = false
  showResendOTP = false

  constructor() {
    makeAutoObservable(this)
  }

  setShowAlertState = (show: boolean) => {
    this.showAlertState = show
    this.isOTPSent = false
    this.showExpiredError = false
  }

  setUserEmail = (email: string) => {
    this.userEmail = email
  }

  setShowExpiredError = (show: boolean) => {
    this.showExpiredError = show
    this.isOTPSent = false
    this.showAlertState = false
  }

  setIsTimerExpired = (show: boolean) => {
    this.isTimerExpired = show
  }

  setIsOTPSent = (sent: boolean) => {
    this.isOTPSent = sent
    this.showAlertState = false
    this.showExpiredError = false
  }

  setShowResendOTP = (show: boolean) => {
    this.showResendOTP = show
  }

  resetRibbonState = () => {
    this.showAlertState = false
    this.showExpiredError = false
    this.isOTPSent = false
  }
}

export const signupAlertState = new SignupAlertState()
