import { PropsWithChildren } from 'react'
import { Button as MuiButton, ButtonProps, makeStyles } from '@material-ui/core'
import { ReactComponent as ArrowForwardPrimaryIcon } from 'icons/arrow-forward-primary-button.svg'
import { ReactComponent as ArrowForwardSecondaryIcon } from 'icons/arrow-forward-secondary-button.svg'
import { useTheme } from 'styled-components'
import styled from 'styled-components/macro'

const StyledPrimaryBackArrow = styled(ArrowForwardPrimaryIcon)`
  && {
    transform: rotateY(180deg);
  }
`

const StyledSecondaryBackArrow = styled(ArrowForwardSecondaryIcon)`
  && {
    transform: rotateY(180deg);
  }
`

const StyledPrimaryMUIButton = styled(MuiButton)<{noPadding?: boolean}>`
  && {
    &.Mui-disabled {
      color: #eeeeee;
    }
    box-shadow: none;
    &.MuiButton-containedPrimary {
      padding: ${props => props.noPadding && "0rem"};
    }
  }
`

const StyledSecondaryMUIButton = styled(MuiButton)`
  && {
    &.Mui-disabled {
      opacity: 0.2;
      border: 2px solid ${props => props.theme.colors.accent1};
    }
  }
`

export interface IButtonProps extends ButtonProps {
  design?: 'primary' | 'secondary' | 'tertiary'
  small?: boolean
  leftIcon?: JSX.Element
  forwardArrowIcon?: boolean
  backArrowIcon?: boolean
  rightIcon?: JSX.Element
  showBorder?: boolean
  noMargin?: boolean
  noPadding?: boolean
}

const useStyles = makeStyles(theme => ({
  startIconStyle:({noMargin}: IButtonProps) => ({
    '& .MuiButton-startIcon': {
      marginLeft: noMargin ? '0px' : '-4px',
      marginRight: noMargin ? '0px' : '8px'
    }
  })
}));

export const Button = ({
  children,
  design,
  small = false,
  leftIcon,
  forwardArrowIcon = false,
  backArrowIcon = false,
  rightIcon,
  showBorder,
  noMargin,
  noPadding,
  ...rest
}: PropsWithChildren<IButtonProps>) => {
  const theme = useTheme()
  const classes = useStyles({noMargin})

  switch (design) {
    case 'primary':
      return (
        <StyledPrimaryMUIButton
          size={small ? 'small' : 'medium'}
          startIcon={backArrowIcon ? <StyledPrimaryBackArrow /> : leftIcon}
          endIcon={forwardArrowIcon ? <ArrowForwardPrimaryIcon /> : rightIcon}
          variant="contained"
          color="primary"
          noPadding={noPadding}
          {...rest}
        >
          {children}
        </StyledPrimaryMUIButton>
      )
    case 'secondary':
      return (
        <StyledSecondaryMUIButton
          size={small ? 'small' : 'medium'}
          startIcon={backArrowIcon ? <StyledSecondaryBackArrow stroke={theme.colors.accent1} /> : leftIcon}
          endIcon={forwardArrowIcon ? <ArrowForwardSecondaryIcon stroke={theme.colors.accent1} /> : rightIcon}
          variant="outlined"
          color="default"
          className={classes.startIconStyle}
          {...rest}
        >
          {children}
        </StyledSecondaryMUIButton>
      )
    case 'tertiary':
      return (
        <StyledSecondaryMUIButton
          variant="text"
          color="default"
          startIcon={backArrowIcon ? <StyledSecondaryBackArrow stroke={theme.colors.accent} /> : leftIcon}
          endIcon={forwardArrowIcon ? <ArrowForwardSecondaryIcon stroke={theme.colors.accent} /> : null}
          style={{
            whiteSpace: 'nowrap',
            border: showBorder ? `1px solid ${theme.colors.supplementary1}` : 'none',
            borderRadius: small ? '8px' : '12px',
            padding: showBorder ? '15px 30px' : '15px 0'
          }}
          {...rest}
        >
          {children}
        </StyledSecondaryMUIButton>
      )
  }
}
