import { Ticket } from './ticket.types'
import { BackendUser } from './user.types'
import { BusinessObject } from '.'
import { SelectOption } from 'pages/administration/components/create-event/IndustriesList'
import { BackendPaginatedResponse } from 'common/types'

export interface BackendEvent extends BusinessObject {
  id?: string
  title: string
  subTitle: string
  description: string
  speakerDetails: any[]
  date: Date
  showKey: string
  status: string
  registrationLink: string
  loginLink: string
  price: number
  metaData?: any
  endDate: string
  startTime: string
  endTime: string
  registrant?: number
  registrationStartDate?: string
  industry?: (string | SelectOption)[]
}

export interface AdminEvent {
  id?: string
  title: string
  subTitle?: string
  type: string
  banner?: string
  emailBanner?: string
  description: string
  speakers: EventSpeaker[]
  agenda?: EventAgenda[]
  sponsors: EventSponsor[]
  cost: EventCost[]
  scheduled: boolean
  registrationStartDate: string
  status: string
  owner?: string
  date: string
  endDate?: string
  startTime?: string
  endTime?: string
  metaData?: any
  media?: string
  thumbnail?: string
  mediaThumbnail?: string
  industry?: (string | SelectOption)[]
  timeZone?: string
  qrCode?: boolean
  isPrivate: boolean
}

export interface AdminEventLocation {
  name: string
  virtualEvent: boolean
  address: string
  eventProvider: string
}

export interface AdminEventDate {
  startDate: string
  endDate: string
  startTime: string
  endTime: string
}
export interface AdminEventSponsor {
  name: string
  industry: string
  sponsorType: string
  sponsorLink: string
  sponsorLogo: string
}

export interface CostDiscount {
  discountRule: 'question' | 'percentage'
  discountValue: string | number
}

export interface EventCost {
  key?: string
  title: string
  amount: number
  benefits?: string[]
  discount?: CostDiscount
  availableQuantity?: number
}

export interface EventSpeaker {
  id?: string
  avatar: string
  name: string
  title: string
  description: string
  bio?: string
  email?: string
  role?: string
  previewImage?: string
  image?: any
}

export interface EventSponsor {
  id?: string
  name: string
  image?: string
  industry: string
  category: string[]
  jobBoardUrl: string
  previewImage?: string
}

export interface EventAgendaDetails {
  key?: string
  orderNo: number
  startTime: string
  endTime: string
  description: string
  moderator: string
  timeZone: string
}
export interface EventAgenda {
  key?: string
  date: string
  day: EventAgendaDetails[]
  startTime?: string
  endTime?: string
}

export interface AttendeeResponse {
  attending: boolean
  ticket: Ticket
  eventAttendee: EventAttendee
}

export interface FrontendEvent {
  data?: any[]
  id?: string
  title: string
  banner?: string
  subTitle?: string
  date: string
  description: string
  type: string
  cost: EventCost[]
  speakers: EventSpeaker[]
  sponsors: EventSponsor[]
  agenda?: EventAgenda[]
  metaData?: any
  endDate?: string
  startTime?: string
  endTime?: string
  status?: string
  loginLink?: string
  ownerFirstName?: string
  ownerLastName?: string
  registrants?: number
  media?: string
  thumbnail?: string
  mediaThumbnail?: string
  industry?: (string | SelectOption)[]
  qrCode?: boolean
  attendanceType?: string
  totalCount?: string
}
export interface EventAttendee extends BusinessObject {
  id?: string
  attendeeId?: string
  eventId?: string
  paymentStatus?: string
  registeredOnIntrado?: boolean
  preferences?: any
  metaData?: any
  attendanceType?: string
  status: EventAttendeeStatus
}

export interface EventPaymentDetails {
  total: number
  hst: any
  amount: number
  quantity: number
  event: string
  selfPurchase: boolean
  discount?: number
}

export interface EventAttendeesPagination extends BackendPaginatedResponse<EventAttendee> {}

export type RegistrantUser = BackendUser & { status: string; attendeeId: string; attendanceType: AttendanceType }

export interface IEventMetrics {
  registrationcount: string
  attendedcount: string
}

export enum EventAttendeeStatus {
  REGISTERED = 'registered',
  ATTENDED = 'attended'
}

export interface IntradoEventLoginResponse {
  response: string
  loginKey: string
}

export enum EventStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  SCHEDULED = 'scheduled'
}

export enum AttendanceType {
  Offline = 'offline',
  Virtual = 'virtual',
  HYBRID = 'Hybrid',
  VIRTUAL = 'Virtual',
  INPERSON = 'In-Person'
}
export interface QueryStringObject {
  [key: string]: string[]
}
export interface EventsQueryStringObject extends QueryStringObject {
  location: string[]
  filter: string[]
  attendanceType: string[]
}
export interface EventCounts {
  [key: string]: number
  liveEventsCounts: number
  allEventsCounts: number
  pastEventsCounts: number
  hybridEventsCounts: number
  inPersonEventsCounts: number
  virtualEventsCounts: number
  usaEventsCounts: number
  canadaEventsCounts: number
}

export interface IFilterType {
  label: string
  filter: string
  filterType: string
  eventCounts: number
}

export interface IFilterGroup {
  label: string
  filters: IFilterType[]
  display: boolean
}

export enum EventEditType {
  UPDATE = 'update'
}
export enum SummitSponsorType {
  TITLE = 'title',
  FOUNDING = 'founding',
  LEADERSHIP = 'leadership',
  COLLABORATING = 'collaborating',
  SUPPORTING = 'supporting'
}

export enum SummitPassType {
  GENERAL = 'General',
  VIP = 'vip'
}
