import { useContext } from 'react'
import ThemeContext from 'contexts/theme'

export const useThemeSwitch = () => {
    const { mode, setMode, setStoredMode } = useContext(ThemeContext)

    const switchTheme = () => {
        setMode()
    }

    const setStoredTheme = (preference) => {
        setStoredMode(preference)
    }
    return {
        mode,
        switchTheme,
        setStoredTheme
    } as const
}