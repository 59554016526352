import {
  useEffect,
  createContext,
  PropsWithChildren,
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  Context as ReactContext
} from 'react'
import {
  BackendPartner,
  BackendUser,
  UserPreference,
  UserFunction,
  UsageSummary,
  BackendSubscription,
  PartnerType
} from 'common/types'
import { useHistory, useLocation } from 'react-router'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import { useUserApi } from 'hooks/use-user-api/useUserApi'
import { usePreferenceApi } from 'hooks/use-preference-api/usePreferenceApi'
import ReactGA from 'react-ga'
import { usePartnerApi } from 'hooks/use-partner-api/usePartnerApi'
import { useUserManagementApi } from 'hooks/use-user-management-api/useUserManagementApi'
import { Loader } from 'components/core/loader'
import { useSocket } from 'hooks/use-socket/useSocket'
import { useNotification } from 'hooks/use-notification/useNotification'
import { useSecurity } from 'hooks/use-security/useSecurity'
import { useUserRedirect } from 'pages/auth/hooks/useUserRedirect'

interface AuthContext {
  backendUser: BackendUser
  backendPartner: BackendPartner
  setUser: (user: BackendUser) => void
  setPartner: (user: BackendPartner) => void
  clearUser: () => void
  refreshUser: () => void
  isAuthenticated: boolean
  login: (type?: 'sso' | 'creds') => void
  logout: (signOut?: boolean) => void
  clearSessions: boolean
  setClearSessions: (value: boolean) => void
  setIsAuthenticated: (status: boolean) => void
  forwardingUrl: any
  setForwardingUrl: (url: string) => void
  error: string
  userPreference: UserPreference
  setPreference: (userPreference: UserPreference) => void
  backendUserFunctions: UserFunction[]
  setUserFunctions: (backendUserFunctions: UserFunction[]) => void
  backendUsageSummary: UsageSummary
  setUsageSummary: (partnerId: string) => void
  subscriptionType: BackendSubscription
}

interface CognitoDetails {
  id: string
}

const Context: ReactContext<AuthContext> = createContext<AuthContext>({
  backendUser: null,
  backendPartner: null,
  setUser: (user: BackendUser) => null,
  setPartner: (partner: BackendPartner) => null,
  clearUser: () => null,
  refreshUser: () => null,
  isAuthenticated: false,
  login: (type?: 'sso' | 'creds') => null,
  logout: () => null,
  clearSessions: false,
  setClearSessions: (value: boolean) => null,
  setIsAuthenticated: (status: boolean) => null,
  error: '',
  forwardingUrl: {},
  setForwardingUrl: (url: string) => null,
  userPreference: null,
  setPreference: (userPreference: UserPreference) => null,
  backendUserFunctions: null,
  setUserFunctions: (backendUserFunctions: UserFunction[]) => null,
  backendUsageSummary: null,
  setUsageSummary: (partnerId: string) => null,
  subscriptionType: null
})

export const AuthProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { getUserFromToken, session_key, getAccessToken } = useSecurity()
  const popup_key = 'isOpen'
  const popup_receiver = 'receiver'
  const partnerconfig = 'partnerconfig'
  const { setItem, getItem, clearItem } = useLocalStorage()
  const [backendUser, setBackendUser] = useState<BackendUser>()
  const [backendPartner, setBackendPartner] = useState<BackendPartner>()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [clearSessions, setClearSessions] = useState(false)
  const [url, setUrl] = useState<any>()
  const history = useHistory()
  const { pathname } = useLocation()
  const { fetchUser, logoutUser, loginUserWithToken } = useUserApi()
  const { fetchPreferenceByUser } = usePreferenceApi()
  const [userPreference, setUserPreference] = useState<UserPreference>()
  const [isAuthCheckDone, setIsAuthCheckDone] = useState(false)
  const { fetchPartner, fetchUsageSummary, fetchPartnerSubscription } = usePartnerApi()
  const { fetchUserFunctionsByUser } = useUserManagementApi()
  const [backendUserFunctions, setUserFunctions] = useState<UserFunction[]>()
  const [backendUsageSummary, setUsageSummary] = useState<UsageSummary>()
  const [subscriptionType, setSubscriptionType] = useState<BackendSubscription>()
  const { socket } = useSocket()

  const { notify } = useNotification()
  const partnerConfig = JSON.parse(getItem('partnerconfig'))
  const partnerType = partnerConfig?.packageType ? PartnerType.Corporate : PartnerType.Community
  const { redirectUser } = useUserRedirect()
  const urlParams = new URLSearchParams(location.search)
  const loginToken = urlParams.get('token')

  const refetchUserAndPartnerDetails = async (user: BackendUser) => {
    const getPartner = async (partnerId: string, welcomed: boolean) => {
      if (welcomed) {
        return backendPartner || fetchPartner(partnerId)
      }
      return fetchPartner(partnerId)
    }
    const getSubscription = async (partnerId: string, welcomed: boolean) => {
      if (welcomed) {
        return subscriptionType || fetchPartnerSubscription(partnerId)
      }
      return fetchPartnerSubscription(partnerId)
    }

    const getUsageSummary = async (partnerId: string, welcomed: boolean) => {
      if (welcomed) {
        return backendUsageSummary || fetchUsageSummary(partnerId)
      }
      return fetchUsageSummary(partnerId)
    }

    const _userPreference: UserPreference = userPreference || (await fetchPreferenceByUser(user?.id))
    if (_userPreference) setUserPreference(_userPreference)
    let _userFunctions: UserFunction[]
    if (backendUserFunctions?.length) {
      _userFunctions = backendUserFunctions
    } else {
      _userFunctions = await fetchUserFunctionsByUser(user?.id)
    }

    if (_userFunctions) setUserFunctions(_userFunctions)
    if (user?.partnerId) {
      const _partner: BackendPartner = await getPartner(user?.partnerId, user?.welcomed)
      if (_partner) {
        setBackendPartner(_partner)
        if (_partner.type === PartnerType.Corporate) {
          const _partnerSubscription = await getSubscription(user?.partnerId, user?.welcomed)
          if (_partnerSubscription) setSubscriptionType(_partnerSubscription)
        }
        const _usageSummary: UsageSummary = await getUsageSummary(user?.partnerId, user?.welcomed)
        if (_usageSummary) setUsageSummary(_usageSummary)
      }
    }
    return
  }

  useEffect(() => {
    const getData = async () => {
      const session = getAccessToken()
      const sessionUrl = getItem('url')
      if (sessionUrl) {
        setUrl(JSON.parse(sessionUrl))
      }
      if (session) {
        if (clearSessions) {
          authLogout()
          return
        }
        const _user: BackendUser = backendUser || (await fetchUser(getUserFromToken()?.id))
        if (_user && _user?.enabled) {
          if (_user?.signoutRequested) {
            authLogout(_user?.signoutRequested)
            return
          }

          if (_user?.blocked) {
            setClearSessions(true)
            authLogout(_user?.blocked)
            notify('error', 'Your account has been blocked')
            history.push('/')
          }

          setBackendUser(_user)
          setIsAuthenticated(true)

          await refetchUserAndPartnerDetails(_user)

          ReactGA.set({
            userId: _user?.id
          })

          socket.auth = { username: _user.username, userId: _user.id }
          socket.connect()

          if (_user.isOnBoarded && JSON.parse(getItem('partnerconfig'))) {
            redirectUser(_user, partnerConfig, partnerType)
          } else if ((pathname === '/' || pathname === '/login' || pathname === '/signup') && _user?.isOnBoarded) {
            history.push('/authenticate')
          }
        } else {
          clear()
        }
      } else if (loginToken && !backendUser) {
        const loginInfo = await loginUserWithToken(loginToken)
        const user = loginInfo.user?.user
        const accessToken = loginInfo.accessToken
        if (user) {
          if (user?.signoutRequested) {
            authLogout()
            return
          }
          if (user?.blocked) {
            notify('error', 'Your account has been blocked!')
            authLogout()
            return
          }
          setBackendUser(user)
          setIsAuthenticated(true)
          setItem(session_key, accessToken)
          await refetchUserAndPartnerDetails(user)
          ReactGA.set({
            userId: user?.id
          })
          socket.auth = { username: user.username, userId: user.id }
          socket.connect()
          if (user.isOnBoarded && JSON.parse(getItem('partnerconfig'))) {
            redirectUser(user, partnerConfig, partnerType)
          } else if ((pathname === '/' || pathname === '/login' || pathname === '/signup') && user?.isOnBoarded) {
            history.push('/authenticate')
          }
        } else {
          clear()
        }

      }
      setIsAuthCheckDone(true)
    }
    getData()
  }, [clearSessions, pathname])

  const refresh = useCallback(async () => {
    const session = getAccessToken()
    if (session) {
      const user: BackendUser = await fetchUser(getUserFromToken()?.id)
      if (user && user?.enabled) {
        setBackendUser(user)
        setIsAuthenticated(true)
        const userFunctions: UserFunction[] = await fetchUserFunctionsByUser(user?.id)
        if (userFunctions) setUserFunctions(userFunctions)
        if (user?.partnerId) {
          const partner: BackendPartner = await fetchPartner(user?.partnerId)
          if (partner) {
            setBackendPartner(partner)
            if (partner.type === 'corporate') {
              const usageSummary: UsageSummary = await fetchUsageSummary(user?.partnerId)
              if (usageSummary) setUsageSummary(usageSummary)
            }
          }
        }
        socket.auth = { username: user.username }
        socket.connect()
        history.push(pathname)
      } else {
        clear()
      }
    }
  }, [])

  const set = useCallback((user: BackendUser) => {
    setBackendUser(user)
  }, [])

  const setPartner = useCallback((user: BackendPartner) => {
    setBackendPartner(user)
  }, [])

  const onClearSessions = useCallback((value: boolean) => {
    setClearSessions(value)
  }, [])

  const clear = useCallback(() => {
    //Clear User here
    setBackendUser(null)
    setBackendPartner(null)
    setUserFunctions(null)
    setUsageSummary(null)
    clearItem(session_key)
    clearItem(popup_key)
    clearItem(popup_receiver)
    clearItem(partnerconfig)
    clearItem('currentStep')
  }, [])

  const setFUrl = useCallback((fUrl: string) => {
    setUrl(url)

    fUrl ? setItem('url', JSON.stringify({ url: fUrl })) : clearItem('url')
  }, [])

  const authLogout = async (signOut?: boolean) => {
    backendUser && (await logoutUser(backendUser?.id))
    clear()
    authenticated(false)
  }

  const authenticated = useCallback((value: boolean) => {
    setIsAuthenticated(value)
  }, [])

  const authLogin = useCallback(() => {
    //
  }, [])

  const setPreferences = useCallback((userPreference: UserPreference) => {
    setUserPreference(userPreference)
  }, [])

  const setBackendUserFunctions = useCallback((userFunctions: UserFunction[]) => {
    setUserFunctions(userFunctions)
  }, [])

  const setBackendUsageSummary = useCallback(async (partnerId: string) => {
    const usageSummary: UsageSummary = await fetchUsageSummary(partnerId)
    if (usageSummary) setUsageSummary(usageSummary)
  }, [])

  const providerValue = useMemo(
    () => ({
      backendUser,
      backendPartner,
      setUser: set,
      setPartner,
      clearUser: clear,
      refreshUser: refresh,
      isAuthenticated,
      login: authLogin,
      logout: authLogout,
      setIsAuthenticated: authenticated,
      clearSessions,
      setClearSessions: onClearSessions,
      forwardingUrl: url,
      setForwardingUrl: setFUrl,
      error: '',
      userPreference,
      setPreference: setPreferences,
      backendUserFunctions,
      setUserFunctions: setBackendUserFunctions,
      backendUsageSummary,
      setUsageSummary: setBackendUsageSummary,
      subscriptionType
    }),
    [
      backendUser,
      backendPartner,
      set,
      clear,
      refresh,
      isAuthenticated,
      authenticated,
      authLogin,
      authLogout,
      userPreference,
      setPreferences,
      backendUserFunctions,
      backendUsageSummary
    ]
  )

  return <Context.Provider value={providerValue}>{isAuthCheckDone ? children : <Loader />}</Context.Provider>
}

export default Context
