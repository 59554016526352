import { BusinessObject } from '.'

export interface QuestionAnswer {
  question_id?: string
  question: string
  answer: string
  is_goals?: boolean
  metadata?: any
}

export type QuestionDisplayType =
  | 'text'
  | 'text-multi'
  | 'select'
  | 'radio-single'
  | 'radio-multi'
  | 'yes-no'
  | 'true-false'
  | 'file'
  | 'complex'
  | 'email'
  | 'switch'
  | 'file-resume'
  | 'checkbox'
  | 'phone'
export type QuestionRole = 'Member' | 'Partner' | 'Admin'

export interface OnboardingQuestion extends BusinessObject {
  id?: string
  question: string
  role?: 'Member' | 'Partner' | 'Admin'
  displayType?: QuestionDisplayType
  options?: string[]
  page?: number
  pageOrder?: number
  enabled?: boolean
  required?: boolean
  userAnswer?: string
  wordCount?: number
  metaData?: any
  isGoals?: boolean
}

export enum Questions {
  FIRST_NAME,
  LAST_NAME,
  GENDER_IDENTITY,
  AGE_RANGE,
  LOCATION,
  DO_YOU_IDENTIFY_AS_BEING_PART_OF_THE_BLACK_COMMUNITY,
  HEADLINE,
  COMPANY_NAME,
  EXPERIENCE_LEVEL,
  INDUSTRY,
  AREA_OF_EXPERTISE,
  WEBSITE,
  WHAT_DO_YOU_WANT_TO_ACCOMPLISH_AT_OBSIDI,
  RESUME,
  AVATAR,
  SHOW_GOALS,
  SHOW_COUNTRY,
  SHOW_PROVINCE,
  SHOW_CITY,
  YEARS_OF_EXPERIENCE,
  SHOW_RESUME,
  RESUME_FILENAME
}
