import { useMemo } from 'react'
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

import { themeDark, themeLight } from './useStyledComponentsTheme'
import { useHexToRGB } from 'hooks/use-hex-to-rgb/useHexToRGB'

/*
  This is the single source of truth for the look and feel
  of all our applications
*/

export const materialUiTheme = (mode: string) => {
  const result = useMemo(
    () => (): ThemeOptions => {
      const theme = mode === 'dark' ? themeLight : themeDark
      return {
        // this section allows us to set colors from our style guide
        palette: {
          primary: {
            light: theme.colors.accent1, // '#EEEEEE', //will be calculated from palette.primary.main,
            main: theme.colors.primary // '#8237B9'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main,
          },
          secondary: {
            // light: will be calculated from palette.primary.main,
            main: theme.colors.secondary // '#1DC5D7'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main,
          },
          error: {
            main: theme.colors.error // '#DF2828'
          },
          action: {
            disabledBackground: useHexToRGB(theme.colors.primary, 0.4),
            disabled: theme.colors.accent
          }
        },
        // setting the font across all components
        typography: {
          fontFamily: theme.fonts.primary, //'Montserrat',
          h1: {
            fontSize: '1.875rem',
            fontWeight: 700,
            lineHeight: '116.7%'
          },
          h2: {
            fontSize: '1.75rem',
            fontWeight: 700,
            lineHeight: '120%'
          },
          h3: {
            fontSize: '1.563rem',
            fontWeight: 700,
            lineHeight: '116.7%'
          },
          h4: {
            fontSize: '1.375rem',
            fontWeight: 700,
            lineHeight: '123.5%'
          },
          h5: {
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: '133.4%'
          },
          h6: {
            fontSize: '1.125rem',
            fontWeight: 700,
            lineHeight: '160%'
          },
          subtitle1: {
            fontSize: '1.063rem',
            fontWeight: 700,
            lineHeight: '150%'
          },
          subtitle2: {
            fontSize: '0.9375rem',
            fontWeight: 700,
            lineHeight: '150%'
          },

          body1: {
            fontSize: '1rem',
            fontWeight: 300,
            lineHeight: '150%'
          },
          body2: {
            fontSize: '0.875rem',
            fontWeight: 300,
            lineHeight: '150%'
          },
          button: {
            fontSize: '1rem',
            fontWeight: 700,
            [theme.breakpoints.small]: {
              fontSize: '0.875rem'
            }
          },
          overline: {
            fontSize: '0.75rem',
            textTransform: 'none',
            lineHeight: 'unset'
          },
          caption: {
            fontSize: '0.625rem',
            fontWeight: 500,
            lineHeight: '166%'
          }
        },
        // this section is where we get to the bare metal of customization of material-ui
        overrides: {
          MuiDialog: {
            paper: {
              overflowY: 'hidden',
              zoom: '80%'
            }
          },
          MuiInputBase: {
            root: {
              borderColor: theme.colors.accent1,
              color: theme.colors.accent1,
              border: `1px solid ${theme.colors.accent1}`,
              borderRadius: '18px',
              fontSize: '1rem'
            }
          },
          MuiAppBar: {
            colorPrimary: {
              // this makes text in the app bar black
              color: theme.colors.supplementary,
              //@ts-ignore
              backgroundColor: null
            }
          },
          MuiCheckbox: {
            colorSecondary: {
              color: theme.colors.accent,
              '&$checked': {
                color: theme.colors.primary
              }
            }
          },
          MuiRadio: {
            colorSecondary: {
              color: theme.colors.accent,
              '&$checked': {
                color: theme.colors.primary
              }
            }
          },
          MuiButton: {
            // Medium (default)
            contained: {
              textTransform: 'none',
              borderRadius: '12px',
              padding: '0.625rem 1.875rem',
              height: '2.1875rem',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '150%',
              letterSpacing: '0.009375rem'
            },
            containedSecondary: {
              color: theme.colors.secondary,
              backgroundColor: theme.colors.supplementary,
              border: `2px solid ${theme.colors.secondary}`
            },
            containedPrimary: {
              color: '#EEEEEE',
              '&:hover': {
                backgroundColor: theme.colors.primary,
                opacity: 0.8
              },
              '&:active': {
                border: `2px solid ${theme.colors.primary}`,
                opacity: 0.800000011920929
              }
            },
            text: {
              textTransform: 'none',
              height: '3.875rem',
              textDecoration: 'underline',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%',
              letterSpacing: '0.009375rem',
              color: theme.colors.accent,
              '&:hover': {
                color: theme.colors.primary,
                textDecoration: 'underline'
              },
              '&:active': {
                color: theme.colors.accent,
                opacity: 0.7
              }
            },
            textSecondary: {
              '&:hover': {
                color: theme.colors.primary,
                textDecoration: 'underline'
              },
              '&:active': {
                color: theme.colors.secondary,
                opacity: 0.699999988079071
              }
            },
            outlined: {
              textTransform: 'none',
              borderRadius: '12px',
              padding: '0.625rem 1.875rem',
              height: '2.1875rem',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '150%',
              letterSpacing: '0.009375rem',
              border: `2px solid ${theme.colors.accent}`,
              color: theme.colors.accent,
              '&:hover': {
                border: `2px solid ${theme.colors.accent}`,
                background: useHexToRGB(theme.colors.accent, 0.2),
              },
              '&:active': {
                background: useHexToRGB(theme.colors.accent, 0.1)
              }
            },
            outlinedPrimary: {
              border: '2px solid'
            },
            outlinedSecondary: {
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
                boxShadow: theme.colors.shadowSmall
              },
              '&:active': {
                opacity: 0.800000011920929
              }
            },
            // Large
            containedSizeLarge: {
              fontSize: '1em',
              padding: '12px 21px'
            },
            outlinedSizeLarge: {
              fontSize: '1em',
              padding: '11px 20px'
            },
            textSizeLarge: {
              padding: '12px 21px'
            },
            // Small
            containedSizeSmall: {
              height: '1.5625rem',
              padding: '0.625rem 1.25rem',
              fontSize: '0.875em',
              borderRadius: '8px'
            },
            outlinedSizeSmall: {
              height: '1.5625rem',
              padding: '0.625rem 1.25em',
              fontSize: '0.875em',
              borderRadius: '8px'
            },
            textSizeSmall: {
              padding: '4px 10px'
            }
          },
          MuiTableRow: {
            root: {
              '&$selected': {
                backgroundColor: theme.colors.bgPrimary
              }
            }
          },
          MuiTableCell: {
            head: {
              fontWeight: 600
            }
          },
          MuiInput: {
            input: {
              padding: '14px',
              fontSize: '0.875rem'
            }
          },
          MuiOutlinedInput: {
            root: {
              borderRadius: '15px'
            },
            input: {
              padding: '14px'
            }
          },
          MuiTab: {
            root: {
              textTransform: 'capitalize',
              fontWeight: 'bold',
              '&$selected': {
                color: theme.colors.primary
              }
            }
          },
          MuiTabs: {
            indicator: {
              height: '4px',
              backgroundColor: theme.colors.primary
            }
          },
          MuiMenu: {
            paper: {
              borderRadius: '8px',
              width: '50%',
              backgroundColor: theme.colors.supplementary,
              color: theme.colors.accent1
            }
          },
          MuiCard: {
            root: {
              backgroundColor: theme.colors.supplementary,
              color: 'white',
              marginTop: '1em',
              borderRadius: '0.7em',
              padding: '0.5em'
            }
          },
          MuiPaper: {
            root: {
              backgroundColor: 'inherit'
            }
          },
          MuiCardContent: {
            root: {
              color: theme.colors.accent1
            }
          },
          MuiMenuItem: {
            root: {
              fontSize: '.85rem',
              paddingTop: '10px',
              paddingBottom: '10px'
            },
            gutters: {
              paddingLeft: '18px',
              paddingRight: '18px'
            }
          }
        }
      }
    },
    [mode, themeLight, themeDark]
  )
  return {
    result
  }
}
