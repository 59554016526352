import {
  createContext,
  PropsWithChildren,
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  Context as ReactContext
} from 'react'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'

type Mode = 'light' | 'dark'

interface ThemeContext {
  mode: Mode
  setMode: () => void
  setStoredMode: (preference: boolean) => void
}

const Context: ReactContext<ThemeContext> = createContext<ThemeContext>({
  mode: null,
  setMode: () => null,
  setStoredMode: (preference) => null
})

export const ThemeProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { setItem, getItem } = useLocalStorage()
  const [themeMode, setThemeMode] = useState<Mode | undefined>(getItem('mode_theme') as Mode)

  const set = useCallback(() => {
    const st_mode = getItem('mode_theme') as Mode
    setThemeMode(st_mode === 'dark' ? 'light' : 'dark')
    setItem('mode_theme', st_mode === 'dark' ? 'light' : 'dark')
  }, [])

  const setStored = useCallback((preference: boolean) => {
    setThemeMode(preference === true ? 'light' : 'dark')
    setItem('mode_theme', preference === true ? 'light' : 'dark')
  }, [])

  const providerValue = useMemo(
    () => ({
      mode: themeMode,
      setMode: set,
      setStoredMode: setStored
    }),
    [themeMode]
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

export default Context
