import { NotificationType, UserNotificationPreference, UserPreference } from 'common/types'
import { get, post, put } from 'hooks/use-http/useHttp'
import { Dispatch, SetStateAction } from 'react'

export const fetchPreferenceByUser = async (userId: string) => {
  const response: UserPreference = await get(`preference/${userId}`, { dieSilent: true })
  return response
}

export const fetchPreferenceByUserWithApiKey = async (userId: string) => {
  const response: UserPreference = await get(`preference/${userId}`, { useApiKey: true, dieSilent: true })
  return response
}

export const createPreference = async (preference: UserPreference) => {
  const response: UserPreference = await post('preference', preference, { dieSilent: true })
  return response
}
export const createUserNotifications = async (preference: UserNotificationPreference) => {
  const response: UserPreference = await post('preference/notification', preference, { dieSilent: true })
  return response
}

export const updatePreference = async (preference: UserPreference, setLoading?: Dispatch<SetStateAction<boolean>>) => {
  setLoading && setLoading(true)
  const response: UserPreference = await put(`preference/${preference?.userId}/${preference?.id}`, preference, {
    dieSilent: true
  })
  setLoading && setLoading(false)
  return response
}

export const fetchNotificationTypes = async () => {
  const response: NotificationType[] = await get(`/notification-types`, { dieSilent: true })
  return response
}

export const fetchAllUserNotificationPreferenceById = async (userId: string) => {
  const response: UserNotificationPreference[] = await get(`/preference/notifications/${userId}`, { dieSilent: true })
  return response
}

export const usePreferenceApi = () => {
  return {
    fetchPreferenceByUser,
    createPreference,
    updatePreference,
    fetchPreferenceByUserWithApiKey,
    createUserNotifications,
    fetchNotificationTypes,
    fetchAllUserNotificationPreferenceById
  } as const
}
