import { FunctionComponent, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogProps,
  Box,
  Typography
} from '@material-ui/core'

//@ts-ignore
import { useTheme } from 'styled-components'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
import { GreyCloseIcon, StyledDialogBox } from './styled'
import styled from 'styled-components/macro'
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'common/atoms/Button/Button'
import { ReactComponent as ArrowForwardSecondaryIcon } from 'icons/arrow-forward-secondary-button.svg'

const StyledDialog = styled(Dialog)`
  && {
    overflow-y: hidden;
    .MuiDialog-paper {
      border-radius: 15px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .MuiDialogContent-root {
      padding: 0px;
      overflow: hidden;
      overflow-wrap: break-word;
      text-align: center;
      align-content: center;
      justify-content: center;
    }
    .MuiButton-label {
      font-weight: bolder;
    }
    .MuiTypography-noWrap {
      white-space: wrap;
      text-align: center;
    }
  }
  &.MuiDialog-paper {
    overflow-y: hidden;
  }
`
const StyledSecondaryBackArrow = styled(ArrowForwardSecondaryIcon)`
  && {
    transform: rotateY(180deg);
  }
`


interface ConfirmModalProps extends DialogProps {
  title: string
  open: boolean
  onClose: (isFromConfirm?: boolean) => void
  onConfirm: () => void
  message?: string
  children?: ReactNode
  cancelText?: string
  confirmText?: string
  fullWidth?: boolean
  dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  icon?: ReactNode
  small?: boolean
  noMessage?: boolean
  wide?: boolean
  showCancel?: boolean
  hideCancelButton?: boolean
  disabled?: boolean
  isReportingContent?: boolean
  cancelButtonDesign?: "primary" | "secondary" | "tertiary"
  forwardArrowIcon?: boolean
  rightIcon?: JSX.Element
}

export const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  title,
  message,
  open,
  onClose,
  onConfirm,
  cancelText,
  confirmText,
  icon,
  small,
  fullScreen,
  dialogWidth,
  noMessage,
  wide,
  showCancel,
  hideCancelButton,
  children,
  disabled,
  isReportingContent,
  cancelButtonDesign,
  forwardArrowIcon,
  rightIcon,
  ...props
}) => {
  const handleConfirm = () => {
    onConfirm()
    onClose(true)
  }

  const theme = useTheme()
  const { isDevice } = useScreenSizes()
  const history = useHistory()

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      {...props}
    >
      <StyledDialogBox
        //width={isDevice ? '75vw' : '34vw'}
        bgcolor={theme.colors.supplementary}
        padding={isDevice ? '0 1em' : '1em 2em'}
        fontWeight="900"
      >
        {showCancel && (
          <Box display="flex" justifyContent="flex-end" onClick={() => onClose ? onClose() : history.goBack()} paddingTop={isDevice ? '0.3em' : '0'}>
            <GreyCloseIcon
              style={{
                cursor: 'pointer',
                color: theme.colors.accent1
              }}
            />
          </Box>
        )}
        <Box display="flex" alignItems="flex-start">
          {icon ? (
            <Box width={isDevice ? '8%' : '6%'} paddingTop="0.5em">
              {icon}
            </Box>
          ) : null}
          <Box width={!icon && wide ? '100%' : '99.8%'}>
            {title != '' && (
              <DialogTitle>
                <Box color={theme.colors.accent1} width="100%">
                  <Typography variant="h6">{title}</Typography>
                </Box>
              </DialogTitle>
            )}
            <Box display="flex" flexDirection="row-reverse" flexGrow={1}></Box>
            {noMessage ? null : (
              <DialogContent>
                <DialogContentText component="span">
                  <Box color={theme.colors.accent1} paddingTop="0.5em">
                    <Typography variant="body1">
                      {message}
                    </Typography>
                  </Box>
                </DialogContentText>
              </DialogContent>
            )}
            {children && <DialogContent>{children}</DialogContent>}
          </Box>
        </Box>
        <DialogActions>
          <Box display="flex" justifyContent={isDevice || hideCancelButton ? 'center' : 'space-between'} width={isReportingContent ? '60%' : '100%'} gridGap={isReportingContent ? '30px' : ''}>
            {!hideCancelButton && (
              <Box width="50%" padding={isReportingContent ? '' : '0 1em'}>
                <Button 
                  design={cancelButtonDesign || 'primary'}
                  size={isDevice ? "small" : "medium"} 
                  onClick={() => {
                    onClose()
                  }} 
                  fullWidth 
                >
                  {cancelText || 'CANCEL'}
                </Button>
              </Box>
            )}
            <Box width="50%" padding={isReportingContent ? '' : '0 1em'}>
              <Button
                design='primary'
                size={isDevice ? "small" : "medium"} 
                fullWidth 
                onClick={handleConfirm} 
                disabled={disabled}
                endIcon={forwardArrowIcon ? <ArrowForwardSecondaryIcon stroke={theme.colors.accent1} /> : rightIcon}
              >
                {confirmText || 'OK'}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </StyledDialogBox>
    </StyledDialog>
  )
}
