import { PartnerType, BackendUser } from "common/types"
import { useLocalStorage, clearItem, setItem } from "hooks/use-local-storage/useLocalStorage"
import { useUserApi } from "hooks/use-user-api/useUserApi"
import { useHistory } from "react-router-dom"

export const useUserRedirect= () => {
    const history = useHistory()
    const { fetchUserRelatedPartner } = useUserApi()
    const { getItem, setItem } = useLocalStorage()

    const getLoggedInPartner = async (partnerId) => {
        if (partnerId) {
          const loggedInPartner = await fetchUserRelatedPartner(partnerId)
          return loggedInPartner
        }
        return null
      }
  
    const redirectUser = async (user: BackendUser, partnerConfig, partnerType: PartnerType) => {
      const loggedInPartner = await getLoggedInPartner(user?.partnerId)
      if (loggedInPartner?.welcomed !== true) {
        setItem('currentStep', '2')
        if (partnerType === PartnerType.Community) {
          history.push('/signup?community=true')
        } else {
          history.push(`/signup?partnerType=${partnerConfig?.packageType}`)
        }
      } else {
        history.push('/feed')
        clearItem('partnerconfig')
      }
    }
  
    return { redirectUser }
  }