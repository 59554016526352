import { Box, Typography } from '@material-ui/core'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
import { MouseEventHandler, ReactNode } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

interface INavButtonProps {
  /** Icon */
  icon: ReactNode
  /** Icon Label */
  label: string

  /** Path that this item links to */
  to?: string
  onClick?: MouseEventHandler<HTMLElement>
  /** Whether this link should use active icon colours. */
  active?: boolean
  /** Same as `to` prop but used mainly for SEO purposes. */
  href?: string
  height?: string
}

const StyledNavItemContainer = styled.a`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 0.25rem;
  }

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const StyledNavButtonWrapper = styled(Box)`
  & {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
  }
`

export const NavButton = ({ icon, label, href, to, onClick, active, height }: INavButtonProps) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const { isDevice } = useScreenSizes()
  const history = useHistory()

  return (
    <StyledNavButtonWrapper height={height || '100%'}>
      <StyledNavItemContainer
        href={href || to}
        onClick={evt => {
          evt.preventDefault()
          !!to && history.push(to)
          onClick?.(evt)
        }}
      >
        <Box>{icon}</Box>
        <Typography variant={isDevice ? "caption" : "body2"}>
          <Box color={pathname === to || active ? theme.colors.primary : theme.colors.accent} whiteSpace="nowrap">{label}</Box>
        </Typography>
      </StyledNavItemContainer>
    </StyledNavButtonWrapper>
  )
}
