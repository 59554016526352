import { readKey, encrypt as openpgpEncrypt, Message } from 'openpgp'
import { UserRoles } from 'common/types'
import { verify, sign } from 'jsonwebtoken'
import { getItem, setItem, clearItem } from 'hooks/use-local-storage/useLocalStorage'

const privateKeyArmored = process.env.REACT_APP_API_PRIVATE_KEY.replace(/\\n/g, '\n')
const publicKeyArmored = process.env.REACT_APP_API_PUBLIC_KEY.replace(/\\n/g, '\n')
const passphrase = process.env.REACT_APP_API_KEY

export const session_key = 'U_A_T'

export const decrypt = async (cipher: any) => {
  return getValueFromToken(cipher)
}

export const base64Encode = (cipher: string) => {
  const result = Buffer.from(cipher).toString('base64')
  return result
}

export const base64Decode = (cipher: string) => {
  const result = Buffer.from(cipher, 'base64').toString('ascii')
  return result
}

export const getValueFromToken = (token: string) => {
  return verify(token, passphrase)
}

export const getJwtTokenFromValue = (value: any) => {
  return sign(value, passphrase)
}

export const getAuthorizationToken = () => {
    let token = getItem(session_key)
    if (!token) {
      return null
    }
    const decodedJwt = decodeToken(token)
    if (hasTokenExpired(decodedJwt)) {
      token = 'expired'
    }
    return token
  }

export const getApiKey = () => {
    return passphrase
  }

export const generateAccessToken = (value: any) => {
  return sign(value, passphrase, { expiresIn: '1w' })
}

export const getAccessToken = () => {
    let token = getItem(session_key)
    return token
  }

export const getUserFromToken = () => {
    const token = getAccessToken()
    if (!token) {
      return null
    }
    const decodedJwt = decodeToken(token)
    const {iat, exp, ...user} = JSON.parse(decodedJwt)
    return user
  }

  const decodeToken = (token: string) => {
    const tokenPayload = token.split('.')[1]
    const payloadInBase64 = tokenPayload?.replace(/-/g, '+').replace(/_/g, '/')
    const decodedJwt = decodeURIComponent(
      Array.prototype.map
        .call(Buffer.from(payloadInBase64, 'base64'), char => {
          return '%' + ('00' + char.toString(16)).slice(-2)
        })
        .join('')
    )
    return decodedJwt
  }

export const hasTokenExpired = (decodedJwt: string) => {
    const { exp } = JSON.parse(decodedJwt)
    const now = Math.floor(Date.now() / 1000)
    return now > exp
  }

export const storeToken = (token: string) => {
    setItem(session_key, token) 
  }

export const useSecurity = () => {
  return {
    getAuthorizationToken,
    getApiKey,
    decrypt,
    generateAccessToken,
    getUserFromToken,
    getAccessToken,
    session_key,
    storeToken,
  } as const
}
