import { FunctionComponent } from 'react'
import { Typography, Box } from '@material-ui/core'
import { StyledMessagingContainer, StyledMessageIcon, AlertContainer } from './style'
import { ReactComponent as NewNotification } from 'icons/notification.svg'
import styled from 'styled-components/macro'
import { Message } from 'common/types/message.types'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'pages/auth/hooks/useAuth'
import { useAnalytics } from 'hooks/use-analytics/useAnalytics'
import { PathName } from 'common/types/pathName.types'
import { StyledNavLinkInner } from '../footer/styles'
import { NavButton } from 'common/atoms/NavButton/NavButton'
import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'

interface NavIconForChatProps {
  theme: any
  messages: Message[]
  setShowNotification: Function
  showNotification: boolean
  pathname?: string
}

const New = styled(NewNotification)`
  top: 0.123em;
  left: 0.07em;
  position: absolute;
  && {
    @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      top: -0.1em;
      left: -0.15em;
    }
  }
`
export const NavIconForChat: FunctionComponent<NavIconForChatProps> = ({
  theme,
  messages,
  showNotification,
  setShowNotification,
  pathname
}) => {
  const { setItem } = useLocalStorage()
  const { isDevice } = useScreenSizes()
  const history = useHistory()
  const { backendPartner, backendUser } = useAuth()
  const { logEventAnalytics } = useAnalytics()

  const handleClick = () => {
    setShowNotification(false)
    if (messages.length > 0) {
      const store = {
        createdAt: messages[0]?.createdAt,
        senderId: messages[0]?.senderId
      }
      setItem('messages', JSON.stringify(store))
    }
  }

  const handleClickMessage = () => {
    const partnerLog = {}
    const userLog = {}
    userLog[MIXPANEL_PROPERTIES?.USER_ID] = backendUser?.id
    if (backendUser?.partnerId) {
      partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
      partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
      partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
    } else {
      userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = backendUser?.role
    }
    logEventAnalytics(
      backendUser?.partnerId ? 'Partner' : backendUser?.role,
      'Message button clicked',
      'both',
      'Message button clicked',
      {
        Email: backendUser?.username,
        ...userLog,
        ...partnerLog,
        'Sendee role': backendUser?.id ? 'Partner' : backendUser?.role
      },
      backendUser?.id,
      {
        $name: `${backendUser?.firstName} ${backendUser?.lastName}`,
        $email: backendUser?.username
      }
    )
    if (isDevice) {
      history.push('/messages')
      handleClick()
    } else {
      handleClick()
    }
  }

  return (
    <NavButton
      to="/messages"
      icon={<StyledMessageIcon pathname={pathname} showNotification={showNotification} />}
      label="Chat"
      onClick={handleClickMessage}
    />
  )
}
