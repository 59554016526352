import { BackendUser } from 'common/types'
import socket from 'common/utils/socket'

const { REACT_APP_SOCKET_URL } = process.env

const getDateAndTime = () => {
    var today = new Date()
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
    return date + ' ' + time
}

const groupBy = (items: any[], key: any) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
)

export const socketGet = <T = any>(name: string, callback: Function) => {
    socket.on(name, (value: any) => {
        callback(value)
    })
}

export const socketSet = <T = any>(name: string, value: any) => {
    socket.emit(name, value as T)
}

export const useSocket = (backendUser?: BackendUser) => {
    return {
        get: socketGet,
        set: socketSet,
        socket
    } as const

}