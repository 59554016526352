import { useState, useLayoutEffect, useMemo, useEffect } from 'react'
import { useHttp } from 'hooks/use-http/useHttp'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'

import { useAuth } from 'pages/auth/hooks/useAuth'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'

const config = {
  bucket: process.env.REACT_APP_AWS_BUCKET_NAME
}
export const useMedia = () => {
  const { get } = useHttp()
  const { setItem, getItem, clearItem } = useLocalStorage()
  const [musicList, setMusicList] = useState<any[]>()
  const [currentSong, setCurrentSong] = useState(``)
  const [pause, setPause] = useState(false)
  const { isDevice } = useScreenSizes()
  const { isAuthenticated } = useAuth()
  const [showMusic, setShowMusic] = useState(false)

  const getData = useMemo(
    () => async () => {
      const response = await getMusicList()
      setMusicList(response)
      const playNumber = Math.floor(Math.random() * response?.length) + 1
      const fileToBePlayed = `https://${config.bucket}.s3.amazonaws.com/${response[playNumber]?.Key}`
      setCurrentSong(fileToBePlayed)
    },
    []
  )

  useEffect(() => {
    setShowMusic(isAuthenticated)
    const pauseMusic = JSON.parse(getItem('musicPaused'))
    if (pauseMusic) {
      setPause(pauseMusic)
    }
  }, [isAuthenticated])

  useLayoutEffect(() => {
    getData()
  }, [])

  const getNewSong = () => {
    const playNumber = Math.floor(Math.random() * musicList?.length) + 1
    const fileToBePlayed = `https://${config.bucket}.s3.amazonaws.com/${musicList[playNumber]?.Key}`
    setCurrentSong(fileToBePlayed)
  }

  const getMusicList = async () => {
    return await get('/admin/music', { useApiKey: true })
  }

  return {
    currentSong,
    getNewSong,
    pause,
    setPause,
    setItem,
    getItem,
    isDevice,
    isAuthenticated,
    showMusic,
    setShowMusic,
    clearItem
  } as const
}
